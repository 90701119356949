import {
    Configuration,
    RedirectRequest,
    SilentRequest,
} from "@azure/msal-browser";

type B2CPolicy = {
    name: string;
    authority: string;
};

type B2CPolicies = {
    authorityDomain: string;
    signUpSignIn: B2CPolicy;
    passwordReset: B2CPolicy;
};

const instance = process.env.REACT_APP_MAINSITE_B2C_INSTANCE as string;
const domain = process.env.REACT_APP_MAINSITE_B2C_DOMAIN as string;
const signUpSignInPolicy = process.env
    .REACT_APP_MAINSITE_B2C_SIGNUP_SIGNIN_POLICY as string;
const passwordResetPolicy = process.env
    .REACT_APP_MAINSITE_B2C_PASSWORD_RESET_POLICY as string;
const clientId = process.env.REACT_APP_MAINSITE_B2C_CLIENT_ID as string;
const apiScope = process.env.REACT_APP_MAINSITE_B2C_API_SCOPE as string;

const b2cPolicies: B2CPolicies = {
    signUpSignIn: {
        name: signUpSignInPolicy,
        authority: `${instance}/${signUpSignInPolicy}`,
    },
    passwordReset: {
        name: passwordResetPolicy,
        authority: `${instance}/${passwordResetPolicy}`,
    },
    authorityDomain: domain,
};

export const msalConfig: Configuration = {
    auth: {
        clientId,
        authority: b2cPolicies.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: `${process.env.REACT_APP_WCC_LOGIN_REDIRECT_URL}/callbacks`,
        postLogoutRedirectUri: "https://www.westminster.gov.uk/",
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export const forgotPasswordRequest: RedirectRequest = {
    authority: b2cPolicies.passwordReset.authority,
    scopes: [apiScope],
};

export const loginRequest: RedirectRequest = {
    prompt: "login",
    authority: b2cPolicies.signUpSignIn.authority,
    scopes: [apiScope],
};

export const logOutRequest: RedirectRequest = {
    authority: b2cPolicies.signUpSignIn.authority,
    scopes: [apiScope],
};

export const tokenRequest: SilentRequest = {
    authority: b2cPolicies.signUpSignIn.authority,
    scopes: [apiScope],
};
