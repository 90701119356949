import React from "react";
import styles from "./Button.module.css";

type ButtonProps = {
    text: string;
    variant?: "primary" | "secondary";
} & Omit<React.ComponentProps<"button">, "className">;

export const Button = ({ text, variant, ...rest }: ButtonProps) => (
    <button className={styles.button} data-type={variant} {...rest}>
        {text}
    </button>
);
