import { assocPath, pathOr } from "ramda";
import { httpsCallable } from "firebase/functions";
import {
    getFirebaseFunctions,
    RECORD_DATA_FUNCTION_NAME,
} from "./firebaseUtils";
import { getOrCreateSessionId, scrollToBottomOfChat } from "./chatUtils";
import {
    QuestionAnswerPair,
    RecordType,
    Report,
    UserDetails,
} from "../types/dataTypes";
import { WasteReport } from "../types/wasteReportTypes";
import { MessageAuthor, MessageType } from "../Components/Chatbot/ChatbotModal";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";

const REPORT_PROGRESS_KEY = "westminster_reportProgress";
const FORMATTED_REPORT_KEY = "westminster_formattedReport";

const REPORT_TRANSFORMATION_KEYS = [
    "ServiceIncidentDetailsObject.CategoryDetailsObject.ActualCategoryDetailsObject.category",
    "ServiceIncidentDetailsObject.CategoryDetailsObject.ActualCategoryDetailsObject.subcategory1",
    "ServiceIncidentDetailsObject.CategoryDetailsObject.ActualCategoryDetailsObject.subcategory2",
    "ServiceIncidentDetailsObject.MeasurementDetailsObject.ActualMeasurementDetailsObject.description",
    "ServiceIncidentDetailsObject.SurfaceDetailsObject.description",
];

type SendReportResponseData = {
    status: string;
};

export const getReportProgress = (): QuestionAnswerPair[] | null => {
    const reportProgress = sessionStorage.getItem(REPORT_PROGRESS_KEY);
    if (reportProgress) {
        const reportProgressArray = JSON.parse(reportProgress);
        if (reportProgressArray.length > 0) {
            return reportProgressArray;
        }
    }
    return null;
};

export const setReportProgress = (
    reportProgress: QuestionAnswerPair[],
): void => {
    sessionStorage.setItem(REPORT_PROGRESS_KEY, JSON.stringify(reportProgress));
};

export const createReport = async (
    location: string | null,
    userDetails: UserDetails | undefined,
): Promise<void> => {
    const functions = getFirebaseFunctions();
    const recordReport = httpsCallable<Report, SendReportResponseData>(
        functions,
        RECORD_DATA_FUNCTION_NAME,
    );

    const recordReportData: Report = {
        recordType: RecordType.Report,
        sessionId: getOrCreateSessionId(),
        reportProgress: getReportProgress() || [],
        location: location,
        userDetails: userDetails,
    };
    await recordReport(recordReportData);
};

export const getFormattedReport = (): WasteReport | null => {
    const formattedReport = sessionStorage.getItem(FORMATTED_REPORT_KEY);
    if (formattedReport) {
        return JSON.parse(formattedReport);
    }
    return null;
};

export const addNewPropertiesToFormattedReport = (
    newProperties: Partial<WasteReport>,
): void => {
    const formattedReport = getFormattedReport() || {};
    const newFormattedReport = deepMerge<Partial<WasteReport>>(
        formattedReport,
        newProperties,
    );
    sessionStorage.setItem(
        FORMATTED_REPORT_KEY,
        JSON.stringify(newFormattedReport),
    );
};

export const resetFormattedReport = (): void => {
    sessionStorage.removeItem(FORMATTED_REPORT_KEY);
};

export const deepMerge = <T>(target: T, ...sources: T[]): T => {
    // This is a solution modified from two SO answers:
    // https://stackoverflow.com/q/39513815/498463 and https://stackoverflow.com/a/383245/498463
    if (Object.isFrozen(target) || !Object.isExtensible(target)) {
        const targetDeepCopy = JSON.parse(JSON.stringify(target));
        // add the now unfrozen and extensible target copy to the front of the
        // sources array.
        sources.unshift(targetDeepCopy);

        // reassign the original target variable to a new object
        target = Object.create({}); // Using Object.create instead of an object literal avoids a typescript error
    }
    for (const source of sources) {
        if (source) {
            for (const [key, val] of Object.entries(source)) {
                if (val !== undefined) {
                    /**
                     * The keys of the T object are not defined as strings
                     *
                     * @ts-expect-error */
                    const existingVal = target[key];

                    let newVal = val;
                    if (
                        existingVal?.constructor === Object &&
                        val?.constructor === Object
                    ) {
                        // we need to recursively merge the objects
                        /**
                         * 2 typescript errors here:
                         * 1) '{}' is assignable to the constraint of type 'T', but 'T' could be instantiated with a different subtype
                         * 2) existingVal and val are of type "unknown" but are expected to be of type T
                         *
                         * @ts-expect-error */
                        newVal = deepMerge<T>({}, existingVal, val);
                    }

                    /**
                     * The keys of the T object are not defined as strings
                     *
                     * @ts-expect-error */
                    target[key] = newVal;
                }
            }
        }
    }
    return target;
};

export const addImageToReport = (url: string, filename: string): void => {
    const fileDetailsObject = {
        name: filename,
        path: url,
    };
    addNewPropertiesToFormattedReport({
        FileDetailsObject: [fileDetailsObject],
    });
};

const addSessionIdToReport = (): void => {
    // There are some fields in the report that are static or date/time based, we can fill them in at the end
    addNewPropertiesToFormattedReport({
        InteractionTransactionDetailsObject: {
            TypeDetailsObject: {
                description: "Chatbot",
                code: getOrCreateSessionId(),
            },
            ReceiptDetailsObject: {
                submission_date: new Date().toISOString(),
                platform: "Ancoris-ChatBot",
                channel: "Online",
            },
        },
    });
};

export const finalizeReport = (
    endpoint: string,
    addMessage: (message: MessageType) => void,
    setChatbotThinking: (isThinking: boolean) => void,
    accessToken?: string,
): void => {
    addSessionIdToReport();
    addBooleansToReport();
    sendReport(endpoint, addMessage, setChatbotThinking, accessToken);
};

export const finalizeReportWithToken = (
    endpoint: string,
    addMessage: (message: MessageType) => void,
    setChatbotThinking: (isThinking: boolean) => void,
    accounts: AccountInfo[],
    instance: IPublicClientApplication,
) => {
    const tokenRequest = {
        account: accounts[0],
        scopes: [process.env.REACT_APP_MAINSITE_B2C_API_SCOPE as string],
    };

    instance.acquireTokenSilent(tokenRequest).then(
        (tokenResponse) => {
            finalizeReport(
                endpoint,
                addMessage,
                setChatbotThinking,
                tokenResponse.accessToken,
            );
        },
        () => {
            finalizeReport(endpoint, addMessage, setChatbotThinking);
        },
    );
};

const addBooleansToReport = (): void => {
    const report = getFormattedReport();
    if (report) {
        const newReport = changeStringsToBooleans(report);
        addNewPropertiesToFormattedReport(newReport);
    }
};

const changeStringsToBooleans = (obj: any): any => {
    // The chatbot sends "TRUE" or "FALSE" for boolean values, but the API expects actual booleans, not strings
    if (obj === "TRUE" || obj === "Y") {
        return true;
    } else if (obj === "FALSE" || obj === "N") {
        return false;
    } else if (typeof obj === "object") {
        for (const [key, val] of Object.entries(obj)) {
            obj[key] = changeStringsToBooleans(val);
        }
    }
    return obj;
};

const getPayloadValue = (item: string) => {
    // Replace hyphen between characters/numbers with underscore
    item = item.replace(/([\w\d])-([\w\d])/g, "$1_$2");
    // Define URL sensitive characters, spaces, and other special characters as a RegExp pattern.
    const pattern = /[^A-Za-z0-9\s_-]/g;
    item = item.replace(pattern, "");
    item = item.replace(/\s+/g, "_").toLowerCase();
    // Replace multiple underscores with a single one
    item = item.replace(/_{2,}/g, "_");
    // Remove trailing underscores
    return item.replace(/_$/g, "");
};

const transformValueAtPath = (pathStr: string, obj: object | null) => {
    const pathArr = pathStr.split(".");
    const value = pathOr("", pathArr, obj);
    const transformedValue = getPayloadValue(value);
    return assocPath(pathArr, transformedValue, obj);
};

const transformReport = (report: WasteReport | null) => {
    return REPORT_TRANSFORMATION_KEYS.reduce(
        (acc, keyPath) => transformValueAtPath(keyPath, acc),
        report,
    );
};

const sendReport = (
    endpoint: string,
    addMessage: (message: MessageType) => void,
    setChatbotThinking: (isThinking: boolean) => void,
    accessToken?: string,
) => {
    setChatbotThinking(true);

    const reportObj = getFormattedReport();
    const updatedReportObj = transformReport(reportObj);

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
        "Ocp-Apim-Subscription-Key",
        process.env.REACT_APP_WCC_SUBSCRIPTION_KEY || "",
    );
    // If we have an access token (if the user is logged in) then add it to the headers
    if (accessToken) {
        headers.append("Authorization", `Bearer ${accessToken}`);
    }

    fetch(endpoint, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(updatedReportObj),
    }).then((response) => {
        const formattedReport = getFormattedReport();
        response.json().then((body) => {
            const CAS = body?.case_reference;
            const SLA = body?.sla_text;
            if (CAS) {
                addMessage({
                    text: "",
                    author: MessageAuthor.ReportCreatedMessage,
                    confirmationFields: {
                        CAS: CAS,
                        SLA: SLA,
                        category:
                            formattedReport?.ServiceIncidentDetailsObject
                                ?.CategoryDetailsObject
                                ?.ActualCategoryDetailsObject?.category || "",
                        emailAddress:
                            formattedReport?.PrimaryConsumerDetailsObject
                                ?.ContactEmailDetailsObject?.[0]?.email_address,
                    },
                });
                // Wait 1 second then add this message to the chat
                // to provide a more natural feel to the chatbot
                setTimeout(() => {
                    addMessage({
                        text: "",
                        author: MessageAuthor.GiveFeedbackMessage,
                    });
                    scrollToBottomOfChat();
                }, 1000);
            } else {
                addMessage({
                    author: MessageAuthor.ReportErrorMessage,
                    text: "There was an error creating your report. Please try again.",
                });
            }
            setChatbotThinking(false);
        });
    })
    .catch(err => {
        console.error(err)
    });
};

export const exportForTesting = {
    getReportProgress,
    setReportProgress,
    createReport,
    getFormattedReport,
    addNewPropertiesToFormattedReport,
    resetFormattedReport,
    deepMerge,
    REPORT_PROGRESS_KEY,
    FORMATTED_REPORT_KEY,
    transformValueAtPath,
    sendReport,
};
