import React, { SyntheticEvent, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "../../Button";
import { loginRequest } from "../../../authConfig";
import { RedirectRequest } from "@azure/msal-browser";
import SubmitReportOptions from "../../../utils/submitOptionsEnum";
import { UserDetails } from "../../../types/dataTypes";

// Insipiration from this source:
// https://codesandbox.io/p/sandbox/typescript-sample-1xct1?file=%2Fsrc%2Fstyles%2Ftheme.ts

type LoginProps = ({
    setSubmitOption,
    setUserDetails,
    userDetailsCollected,
}: {
    setSubmitOption: ({
        value,
        displayText,
    }: {
        value: string;
        displayText: string;
    }) => void;
    setUserDetails: (details: UserDetails) => void;
    userDetailsCollected: boolean;
}) => React.ReactElement | null;

interface LoginButtonEvent extends SyntheticEvent<HTMLButtonElement> {
    payload?: RedirectRequest;
}

const Login: LoginProps = ({
    setSubmitOption,
    setUserDetails,
    userDetailsCollected,
}) => {
    const { instance, accounts } = useMsal();
    const accountsAvailable = accounts.length;

    useEffect(() => {
        if (accountsAvailable > 0) {
            setUserDetails({
                isAnon: false,
                firstName: accounts[0].name,
                lastName: "",
                email: accounts[0].username,
            });
        }
    }, [accounts, setSubmitOption, setUserDetails, accountsAvailable]);

    const handleLogin = async (event: LoginButtonEvent) => {
        const cancelRequestTmp = loginRequest;
        const payload = event.payload;

        setSubmitOption({
            value: SubmitReportOptions.MyWestminster,
            displayText: "Login / Sign up",
        });

        if (accountsAvailable === 0) {
            try {
                if (payload && payload.state) {
                    cancelRequestTmp.state = payload.state;
                }
                await instance.loginRedirect(cancelRequestTmp);
            } catch (error) {
                console.error("Login Error:", error);
            }
        }
    };

    return (
        <Button
            variant="secondary"
            disabled={userDetailsCollected}
            onClick={handleLogin}
            text="Login / Sign up"
        />
    );
};

export default Login;
