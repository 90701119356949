import React from "react";
import styles from "./MessageOptions.module.css";
import { MessageAuthor, MessageType } from "../../Chatbot/ChatbotModal";
import { sendMessageToChatbotAndAddResponse } from "../../../utils/chatUtils";

type MessageOptionsType = ({
    addMessage,
    message,
    chatbotThinking,
    setChatbotThinking,
    title,
    fullWidthOnMobile,
    disabled,
    customMessageHandler,
}: {
    addMessage: (message: MessageType) => void;
    message: MessageType;
    chatbotThinking: boolean;
    setChatbotThinking: (isThinking: boolean) => void;
    title?: string;
    fullWidthOnMobile?: boolean;
    disabled?: boolean;
    customMessageHandler?: (userMessage: string) => void;
}) => React.ReactElement | null;

export const MessageOptions: MessageOptionsType = ({
    addMessage,
    message,
    chatbotThinking,
    setChatbotThinking,
    title,
    fullWidthOnMobile,
    disabled,
    customMessageHandler,
}) => {
    const submitSuggestion = async (
        suggestion: string,
        displayText?: string,
    ) => {
        if (!chatbotThinking) {
            if (customMessageHandler) {
                customMessageHandler(suggestion);
            } else {
                addMessage({
                    text: suggestion,
                    displayText: displayText,
                    author: MessageAuthor.User,
                });
                setChatbotThinking(true);

                await sendMessageToChatbotAndAddResponse(
                    suggestion,
                    addMessage,
                );

                setChatbotThinking(false);
            }
            // If we're on the feedback survey, we don't want to send the message to the chatbot,
            // but we do want to add the message to the chat history
        }
    };

    const options = message.advancedOptions || message.options;

    const getButtonStyle = () => {
        const styleList = [styles.suggestion];
        if (fullWidthOnMobile) {
            styleList.push(styles.fullWidthSuggestion);
        }
        if (disabled) {
            styleList.push(styles.disabled);
        }
        return styleList.join(" ");
    };

    return (
        <div
            className={[
                styles.messageOptionsWithMessage,
                fullWidthOnMobile ? styles.messageOptions : "",
            ].join(" ")}
        >
            {title ? <p className={styles.title}>{title}</p> : null}
            <div className={styles.optionsContainer}>
                {options?.map((option, index) => {
                    const displayText =
                        typeof option === "string" ? option : option.text;
                    const value =
                        typeof option === "string" ? option : option.value;
                    return (
                        <button
                            className={getButtonStyle()}
                            key={index}
                            onClick={async () => {
                                if (disabled) {
                                    return;
                                }
                                await submitSuggestion(value, displayText);
                            }}
                        >
                            {displayText}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};
