import React from "react";
import { ChatbotButton } from "../Chatbot/ChatbotButton";
import styles from "./App.module.css";
import FlagsProvider from "../../contexts/FlagsProvider/FlagsProvider";
import useAuth from "../../hooks/useAuth";

function App() {
    useAuth();

    return (
        <FlagsProvider>
            <div className={styles.app}>
                <ChatbotButton />
            </div>
        </FlagsProvider>
    );
}

export default App;
