import React from "react";

import styles from "./Lightbox.module.css";
import CloseIcon from "../../../styles/assets/close.svg";
import { useFocusTrap } from "../../../utils/focusTrap";

type LightboxType = ({
    imageUrl,
    closeLightbox,
}: {
    imageUrl: string | undefined;
    closeLightbox: () => void;
    buttons: React.ReactElement[];
}) => React.ReactElement | null;

export const Lightbox: LightboxType = ({
    imageUrl,
    closeLightbox,
    buttons,
}): React.ReactElement => {
    const modalRef = useFocusTrap(closeLightbox);
    
    return (
        <div
            className={styles.lightbox}
            ref={modalRef}
            tabIndex={0}
            role="dialog"
            aria-modal="true"
        >
            <button className={styles.closeIconContainer} onClick={closeLightbox}>
                <img alt="Close" src={CloseIcon} />
            </button>
            <div className={styles.lightboxContents}>
                <img alt="Uploaded" className={styles.image} src={imageUrl} />
                {
                    <div className={styles.customButtonsContainer}>
                        {buttons.map((button) => (
                            <div key={button.key}>{button}</div>
                        ))}
                    </div>
                }
            </div>
        </div>
    );
};
