import React, { useEffect } from "react";
import styles from "./Confirmation.module.css";
import { MessageAuthor, MessageType } from "../../Chatbot/ChatbotModal";
import { scrollToBottomOfChat } from "../../../utils/chatUtils";

type ConfirmationType = ({
    message,
}: {
    message: MessageType;
}) => React.ReactElement | null;

const reportCreatedMessageText =
    "Thank you for reporting your issue. Your report has been submitted.";

export const Confirmation: ConfirmationType = ({ message }) => {
    const CAS = message.confirmationFields?.CAS;
    const category = message.confirmationFields?.category;
    const SLA = message.confirmationFields?.SLA;

    const emailAddress = message.confirmationFields?.emailAddress;

    useEffect(() => {
        scrollToBottomOfChat();
    }, []);

    const getConfirmationEmailMessage = (email_address: string) => {
        return (
            <p>
                A confirmation email has been sent to{" "}
                <span className={styles.keyInfo}>{email_address}</span>
            </p>
        );
    };

    const getReferenceNumberMessage = (reference_number: string) => {
        return (
            <p className={styles.referenceNumber}>
                Your reference number is{" "}
                <span className={styles.keyInfo}>{reference_number}</span>
            </p>
        );
    };

    const getSLAMessage = (SLA: string) => {
        return (
            <p className={styles.SLA}>
                We will review your report within {SLA}
            </p>
        );
    };

    const getCategoryMessage = (category: string) => {
        return (
            <p>
                You have reported{" "}
                <span className={styles.keyInfo}>{category}</span>
            </p>
        );
    };

    return (
        <>
            {message.author === MessageAuthor.ReportCreatedMessage ? (
                <>
                    <p className={styles.reportSubmitted}>
                        {reportCreatedMessageText}
                    </p>
                    {category ? getCategoryMessage(category) : null}
                    {SLA ? getSLAMessage(SLA) : null}
                    {emailAddress
                        ? getConfirmationEmailMessage(emailAddress)
                        : null}
                    {CAS ? getReferenceNumberMessage(CAS) : null}
                </>
            ) : (
                <p>{message.text}</p>
            )}
        </>
    );
};
