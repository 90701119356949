import React from "react";
import styles from "./UploadedImage.module.css";
import { MessageType } from "../../Chatbot/ChatbotModal";

type UploadedImageType = ({
    message,
    index,
    onImageClicked,
}: {
    message: MessageType;
    index: number | undefined;
    onImageClicked: () => void;
}) => React.ReactElement | null;

export const UploadedImage: UploadedImageType = ({
    message,
    onImageClicked,
}) => {
    return (
        <div>
            <div className={styles.messageContainer}>
                <button
                    className={styles.imageSquare}
                    onClick={onImageClicked}
                    aria-label="Image, click to re-upload"
                >
                    <img
                        alt="Uploaded"
                        className={styles.uploadedImage}
                        src={message.imageUrl}
                    />
                </button>
            </div>

            {message.classification ? (
                <div className={styles.classification}>
                    <p>From your picture, we have found {message.classification}.</p>
                </div>
            ) : null}
        </div>
    );
};
