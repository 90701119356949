import { getAuth, signInAnonymously } from "firebase/auth";
import { initFirebase } from "../utils/firebaseUtils";

/**
 * Anonymously authenticate the current user or return current user
 * data if user is already authenticated
 *
 * Note: Firebase initializeApp must be run before this hook will work
 *
 * @returns User details as firebase UserImpl object
 */
function useAuth() {
    // initializeApp() must be run before getAuth() will work
    initFirebase();

    const auth = getAuth();
    if (auth.currentUser) {
        return auth.currentUser;
    }
    signInAnonymously(auth)
        // This promise does resolve with a UserCredentialImpl object
        // but we aren't using it here, it may  be useful in the future though
        .then(() => {
            return auth.currentUser;
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(errorCode, errorMessage)
        });
}

export default useAuth;
