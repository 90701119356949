import {
    getFirebaseFunctions,
    RECORD_DATA_FUNCTION_NAME,
} from "./firebaseUtils";
import { httpsCallable } from "firebase/functions";
import { UserMessageSource, MessageType } from "../Components/Chatbot/ChatbotModal";
import { getChatHistory, getOrCreateSessionId } from "./chatUtils";
import { createReport } from "./reportUtils";
import { Metrics, RecordType, UserDetails, ChatHistory } from "../types/dataTypes";
import { reject, includes } from 'ramda';

const END_METRICS_SENT = "westminster_endMetricsSent";

type SendMetricsResponseData = {
    status: string;
};

export const setStartMetricsSent = (sent: boolean) => {
    sessionStorage.setItem("startMetricsSent", sent ? "true" : "false");
};

export const getStartMetricsSent = (): boolean => {
    const startMetricsSent = sessionStorage.getItem("startMetricsSent");
    return startMetricsSent === "true";
};

export const setEndMetricsSent = (sent: boolean) => {
    sessionStorage.setItem(END_METRICS_SENT, sent ? "true" : "false");
};

export const getEndMetricsSent = (): boolean => {
    const endMetricsSent = sessionStorage.getItem(END_METRICS_SENT);
    return endMetricsSent === "true";
};

export const sendToRecordFunction = async <T, R>(data: T, functionName: string) => {
    const functions = getFirebaseFunctions();
    const recordData = httpsCallable<T, R>(functions, functionName);
    await recordData(data);
};

export const sendMetrics = async (metrics: Metrics) => {
    await sendToRecordFunction<Metrics, SendMetricsResponseData>(metrics, RECORD_DATA_FUNCTION_NAME)
};

export const sendChatHistory = async (chatHistory: ChatHistory) => {
    await sendToRecordFunction<ChatHistory, SendMetricsResponseData>(chatHistory, RECORD_DATA_FUNCTION_NAME)
};

export const recordConversationStart = async (
    startMethod: UserMessageSource,
): Promise<void> => {
    // BigQuery timestamps use a different range than js timestamps
    // To convert, simply divide by 1000
    const now = Date.now() / 1000;
    const metrics: Metrics = {
        recordType: RecordType.Metrics,
        sessionId: getOrCreateSessionId(),
        timestamp: now,
        sessionStartTime: now,
        startMethod: startMethod,
    };
    await sendMetrics(metrics);
};

const authorsToSkip = [
    2,  // imageUpload
    4,  // Select Location
    12, // Submit Options
    15, // Bot/ImageClassifier Welcomes
    16] // Empty Welcome
const isAuthorInSkipList = (chat: MessageType) => includes(chat.author, authorsToSkip);


export const recordConversationEnd = async (
    finalCategory: string | undefined,
    numberOfMessages: number,
    location: string | null,
    userDetails: UserDetails | undefined,
): Promise<void> => {
    const createReportPromise = createReport(location, userDetails);
    setEndMetricsSent(true);
    // BigQuery timestamps use a different range than js timestamps
    // To convert, simply divide by 1000
    const now = Date.now() / 1000;
    const metrics: Metrics = {
        recordType: RecordType.Metrics,
        sessionId: getOrCreateSessionId(),
        timestamp: now,
        sessionEndTime: now,
        finalCategory: finalCategory,
        numberOfMessages: numberOfMessages,
    };

    const filteredChatHistory = reject(isAuthorInSkipList, getChatHistory());
    const chatHistory: ChatHistory = {
        recordType: RecordType.ChatHistory,
        sessionId: getOrCreateSessionId(),
        chat: filteredChatHistory
    }

    await Promise.all([sendMetrics(metrics), sendChatHistory(chatHistory), createReportPromise]);
};
