import styles from "./ChatbotButton.module.css";
import React from "react";
import avatar from "../../../styles/assets/avatar.svg";
import { ChatbotModal } from "../ChatbotModal";

export const ChatbotButton = (): React.ReactElement => {
    const [minimized, setMinimized] = React.useState(false);
    return (
        <div>
            {minimized ? (
                <button
                    className={styles.openChatbot}
                    onClick={() => setMinimized(false)}
                >
                    <img
                        src={avatar}
                        alt="Open chatbot"
                        className={styles.chatbotIcon}
                    />
                </button>
            ) : (
                <ChatbotModal closeModal={() => setMinimized(true)} />
            )}
        </div>
    );
};
