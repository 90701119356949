import { ChatHistoryType, UserMessageSource } from "../Components/Chatbot/ChatbotModal";

export type QuestionAnswerPair = {
    question: string;
    answer: string;
    // If the question can be mapped to a field in the Dynamics API then this
    // field will be populated with the name of the field. Otherwise, it will be
    // null.
    questionMapping?: string;
};
export enum RecordType {
    Feedback = "feedback",
    Metrics = "metrics",
    Report = "report",
    ChatHistory = "chatHistory"
}

type StreamedData = {
    recordType: RecordType;
};

export type Feedback = StreamedData & {
    sessionId: string;
    questionsAndAnswers: string;
};

export type Metrics = StreamedData & {
    sessionId: string;
    timestamp: number;
    sessionStartTime?: number;
    startMethod?: UserMessageSource;
    sessionEndTime?: number;
    finalCategory?: string;
    numberOfMessages?: number;
};

export type ChatHistory = StreamedData & {
    sessionId: string;
    chat: ChatHistoryType;
};

export type Report = StreamedData & {
    sessionId: string;
    reportProgress: QuestionAnswerPair[];
    location: string | null;
    userDetails?: UserDetails;
};

export type Coordinates = {
    lat: number;
    lng: number;
};

export type UserDetails = {
    isAnon?: boolean;
    firstName?: string;
    lastName?: string;
    email?: string;
    consumerId?: string;
};

export type SubmitOptionType = {
    value: string;
    displayText: string;
};
