import {
    ChatHistoryType,
    MessageAuthor,
    MessageType,
} from "../Components/Chatbot/ChatbotModal";
import {
    GET_CHATBOT_MESSAGE_FUNCTION_NAME,
    getFirebaseFunctions,
} from "./firebaseUtils";
import { httpsCallable } from "firebase/functions";
import { v4 as uuidv4 } from "uuid";
import { ImageLocation } from "../Components/CustomMessages/ImageUpload";
import {
    addNewPropertiesToFormattedReport,
    getReportProgress,
    setReportProgress,
} from "./reportUtils";
import { QuestionAnswerPair } from "../types/dataTypes";
import { ServiceLocationDetailsObject } from "../types/wasteReportTypes";

type SendMessageRequestData = {
    newMessage: string;
    chatHistory: MessageType[];
    reportProgress: QuestionAnswerPair[] | null;
};

type SendMessageResponse = {
    message: string;
    status: string;
    reportProgress: QuestionAnswerPair[] | null;
    completedForm: boolean;
    category?: string;
    options?: string[];
};

type submitMessageType = (message: string) => Promise<SendMessageResponse>;

const CHAT_HISTORY_KEY = "westminster_chatHistory";
const SESSION_ID_KEY = "westminster_sessionId";
const REPORT_LOCATION_KEY = "westminster_reportLocation";
const LOCATION_SAVED_KEY = "westminster_locationSaved";
const REPORT_CATEGORY_KEY = "westminster_reportCategory";

export const getInitialMessages = (): ChatHistoryType => {
    return [
        // The IntroMessage author is not parsed by the chatbot
        // which is important because some messages here can confuse the chatbot
        // but the chatbot still requires a message to begin with
        {
            text: "What would you like to report?",
            author: MessageAuthor.Chatbot,
            hidden: true,
        },
        {
            text: "Hi! I'm Westminster City Council's reporting assistant. I can help you report a rubbish or street cleaning problem. ",
            author: MessageAuthor.IntroMessage,
        },
        {
            text: "If you have a photo of the problem, I can use it to predict the category and location. If you don't have a photo that's OK, you can start typing your issue below.",
            author: MessageAuthor.IntroMessage,
        },
        {
            text: "",
            author: MessageAuthor.ImageDisclaimer,
        },
        {
            text: "imageUpload",
            author: MessageAuthor.ImageUpload,
        },
    ];
};

export const getOrCreateSessionId = (): string => {
    const existingId = sessionStorage.getItem(SESSION_ID_KEY);
    if (existingId) {
        return existingId;
    } else {
        const newId = uuidv4();
        sessionStorage.setItem(SESSION_ID_KEY, newId);
        return newId;
    }
};

export const generateNewSessionId = (): string => {
    sessionStorage.removeItem(SESSION_ID_KEY);
    return getOrCreateSessionId();
};

export const setChatHistory = (chatHistory: ChatHistoryType): void => {
    sessionStorage.setItem(CHAT_HISTORY_KEY, JSON.stringify(chatHistory));
};

export const getChatHistory = (): ChatHistoryType => {
    const chatHistory = sessionStorage.getItem(CHAT_HISTORY_KEY);
    if (chatHistory) {
        const chatHistoryArray = JSON.parse(chatHistory);
        if (chatHistoryArray.length > 0) {
            return chatHistoryArray;
        }
    }
    // If there is no chat history, assume it's a new session
    generateNewSessionId();
    return getInitialMessages();
};

export const sendMessageToChatbot: submitMessageType = async (message) => {
    const functions = getFirebaseFunctions();

    const sendMessage = httpsCallable<
        SendMessageRequestData,
        SendMessageResponse
    >(functions, GET_CHATBOT_MESSAGE_FUNCTION_NAME);

    const chatHistory = getChatHistory();
    const reportProgress = getReportProgress();
    const { data } = await sendMessage({
        newMessage: message,
        chatHistory: chatHistory,
        reportProgress: reportProgress,
    });

    if (data.category) {
        setReportCategory(data.category);
    }

    setReportProgress(data.reportProgress || []);
    data.reportProgress?.forEach((pair) => {
        if (pair.questionMapping && pair.answer) {
            addNewPropertiesToFormattedReport(
                createObjectFromPathAndValue(pair.questionMapping, pair.answer),
            );
        }
    });

    return data;
};

export const sendMessageToChatbotAndAddResponse = async (
    message: string,
    addMessage: (message: MessageType) => void,
): Promise<void> => {
    const chatbotResponse = await sendMessageToChatbot(message);
    addMessage({
        text: chatbotResponse.message,
        author: MessageAuthor.Chatbot,
        completedForm: chatbotResponse.completedForm,
        category: chatbotResponse.category,
        options: chatbotResponse.options,
    });
};

// The path that each question needs to be mapped to in the API is stored in the CSV in the following format:
// ObjectName.PropertyName.SubPropertyName.SubSubPropertyName etc...
// e.g. ServiceIncidentDetailsObject.CategoryDetailsObject.ActualCategoryDetailsObject.category
// This function takes that string and creates an object with the correct nested properties and the value
const createObjectFromPathAndValue = (
    path: string,
    value: any,
): Record<string, any> => {
    const pathSplit = path.split(".");
    const nestedProperties = pathSplit.reduceRight(
        (acc, curr) => ({ [curr]: acc }),
        value,
    );
    return nestedProperties;
};

export const getAddress = async (
    imageLocation: ImageLocation,
): Promise<string | null> => {
    // Make a request to the google maps API to get the address from the lat and lng
    if (imageLocation?.latitude && imageLocation?.longitude) {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${imageLocation.latitude},${imageLocation.longitude}&key=${process.env.REACT_APP_GEOCODE_KEY}`;

        const response = await fetch(url);
        const responseJson = await response.json();
        parseAndStoreGeocodeResponse(
            responseJson?.results?.[0]?.address_components,
        );

        return responseJson?.results?.[0]?.formatted_address;
    }
    return null;
};

type AddressComponent = {
    long_name: string;
    short_name: string;
    types: string[];
};
export const parseAndStoreGeocodeResponse = (
    addressComponents?: AddressComponent[],
): void => {
    const integrationComponents: Partial<ServiceLocationDetailsObject> = {};
    // A full list of possible types can be found here: https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
    // These are all the types that are known to be mapped to fields in the API
    addressComponents?.forEach((component: AddressComponent) => {
        if (
            component.types.includes("street_number") ||
            component.types.includes("premise") ||
            component.types.includes("establishment")
        ) {
            integrationComponents.address_line_1 = component.long_name;
        } else if (
            component.types.includes("route") ||
            component.types.includes("neighborhood")
        ) {
            integrationComponents.address_line_2 = component.long_name;
        } else if (component.types.includes("postal_town")) {
            integrationComponents.town_city = component.long_name;
        } else if (component.types.includes("administrative_area_level_2")) {
            integrationComponents.county = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
            integrationComponents.country = component.long_name;
        } else if (component.types.includes("postal_code")) {
            integrationComponents.postcode = component.long_name;
        }
    });

    // Delete any existing location details from the report
    // In case the location has come from the image and is being edited here
    // Without doing this, if some address components are missing from the new address
    // The old ones will remain in the report
    addNewPropertiesToFormattedReport({
        ServiceDeliveryLocationDetailsObject: {
            ServiceLocationDetailsObject: {},
        },
    });
    addNewPropertiesToFormattedReport({
        ServiceDeliveryLocationDetailsObject: {
            ServiceLocationDetailsObject: integrationComponents,
        },
    });
};

export const setReportLocation = (
    latitude?: string,
    longitude?: string,
    address?: string,
) => {
    if (!(latitude && longitude)) {
        sessionStorage.removeItem(REPORT_LOCATION_KEY);
    } else {
        sessionStorage.setItem(
            REPORT_LOCATION_KEY,
            JSON.stringify({
                latitude,
                longitude,
                address,
            }),
        );
    }
};

export const getLocationFromSessionStorage = (): string => {
    const location = sessionStorage.getItem(REPORT_LOCATION_KEY);
    if (location) {
        return location;
    }
    return "";
};

export const setLocationSavedInSessionStorage = (saved: boolean) => {
    sessionStorage.setItem(LOCATION_SAVED_KEY, String(saved));
};

export const getLocationSavedInSessionStorage = (): boolean => {
    const locationSaved = sessionStorage.getItem(LOCATION_SAVED_KEY);
    return locationSaved === "true";
};

export const setReportCategory = (category?: string) => {
    if (!category) {
        sessionStorage.removeItem(REPORT_CATEGORY_KEY);
    } else {
        sessionStorage.setItem(REPORT_CATEGORY_KEY, category);
    }
};

export const getReportCategory = (): string => {
    const category = sessionStorage.getItem(REPORT_CATEGORY_KEY);
    if (category) {
        return category;
    }
    return "";
};

export const scrollToBottomOfChat = () => {
    const messagesContainer = document.getElementById("messagesContainer");
    messagesContainer?.scrollTo({
        top: messagesContainer.scrollHeight,
        behavior: "smooth",
    });
};

export const exportForTesting = {
    getInitialMessages,
    getOrCreateSessionId,
    generateNewSessionId,
    setChatHistory,
    getChatHistory,
    sendMessageToChatbot,
    getAddress,
    setReportCategory,
    getReportCategory,
    CHAT_HISTORY_KEY,
    SESSION_ID_KEY,
    REPORT_CATEGORY_KEY,
};
