import { MessageAuthor, MessageType } from "../Components/Chatbot/ChatbotModal";
import { sendMessageToChatbotAndAddResponse } from "./chatUtils";

export const categoryFirstLetterUppercase = (category: string) => {
    const categoryLower = category.toLowerCase();
    // Capitalize the first letter of the category
    return categoryLower.charAt(0).toUpperCase() + categoryLower.slice(1);
};

export const replaceDashesAndUnderscores = (
    str: string,
    substitute: string,
) => {
    return str.replace(/-|\s|_/g, substitute);
};

export const isCategorySameAsClassification = (
    classification: string,
    category: string,
): boolean => {
    if (category === "") return true; // If no category is selected, then the classification is correct

    // Regex to remove spaces, dashes and underscores from the category and classification
    const categoryRegex = replaceDashesAndUnderscores(category, "");
    const classificationRegex = classification?.replace(/-|\s|_/g, "");

    return categoryRegex?.toLowerCase() === classificationRegex?.toLowerCase();
};

export const getFileName = (fileName: string) => {
    return `${Date.now()}_${fileName}`;
};

export const base64ToArrayBuffer = (base64: string) => {
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
};

const sendCategoryConfirmationMessage = (
    category: string,
    addMessage: (message: MessageType) => void,
) => {
    addMessage({
        author: MessageAuthor.ImageClassification,
        text: `Is ${replaceDashesAndUnderscores(category, " ")}, correct?`,
        advancedOptions: [
            {
                text: "Yes",
                value: category,
            },
            {
                text: "No",
                value: "",
            },
        ],
    });
};

export const getResponseToNoClassification = async (
    addMessage: (message: MessageType) => void,
    category?: string,
) => {
    if (category) {
        sendCategoryConfirmationMessage(category, addMessage);
    } else {
        //  Send an empty string to the chatbot so it
        //  will respond with suggestions
        await sendMessageToChatbotAndAddResponse("", addMessage);
    }
};
