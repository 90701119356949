import React from "react";
import styles from "./ScreenReaderOnly.module.css";

type ScreenReaderOnlyProps = {
    children?: React.ReactNode;
    tag: keyof JSX.IntrinsicElements;
    // we want to keep any here to accept any HTML tag
    // source:  https://www.totaltypescript.com/react-component-props-type-helper
} & Omit<React.ComponentProps<any>, "className">;

// This component is used to hide text from the screen, but still have it read by screen readers.
export const ScreenReaderOnly = ({
    children,
    tag,
    ...rest
}: ScreenReaderOnlyProps) => {
    const CustomTag = tag;

    return (
        <CustomTag className={styles.hideVisually} {...rest}>
            {children}
        </CustomTag>
    );
};
