import React from "react";
import styles from "./RestartReport.module.css";
import { useFocusTrap } from "../../../utils/focusTrap";

type RestartReportType = ({
    closeModal,
    restartReport,
}: {
    closeModal: () => void;
    restartReport: () => void;
}) => React.ReactElement | null;

export const RestartReport: RestartReportType = ({
    closeModal,
    restartReport,
}): React.ReactElement => {
    const modalRef = useFocusTrap(closeModal);

    return (
        <div
            className={styles.scrim}
            ref={modalRef}
            tabIndex={0}
            role="dialog"
            aria-modal="true"
        >
            <div className={styles.restartReport}>
                <p className={styles.title}>Restart report?</p>
                <p className={styles.body}>
                    Are you sure you want to restart your report?
                </p>
                <div className={styles.buttons}>
                    <button
                        tabIndex={0}
                        className={styles.button}
                        onClick={() => {
                            closeModal();
                            restartReport();
                        }}
                    >
                        Yes
                    </button>
                    <button
                        tabIndex={0}
                        className={styles.button}
                        onClick={closeModal}
                    >
                        No
                    </button>
                </div>
            </div>
        </div>
    );
};
