import { useRef, useEffect } from "react";

// Source inspiration:
// https://medium.com/cstech/achieving-focus-trapping-in-a-react-modal-component-3f28f596f35b
export const useFocusTrap = (closeHandler: () => void) => {
    // this hook is to create a focus trap in modals for screen readers.
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const element = ref.current;

        if (element) {
            // Focus the modal when it is mounted
            element.focus();

            const handleKeyDown = (e: KeyboardEvent) => {
                if (e.key === "Tab") {
                    e.preventDefault();

                    const focusableElements = Array.from(
                        element.querySelectorAll(
                            "a[href], button, textarea, input, select",
                        ),
                    ) as HTMLElement[];

                    const firstFocusable = focusableElements[0];
                    const lastFocusable =
                        focusableElements[focusableElements.length - 1];

                    const activeElement = document.activeElement as HTMLElement;

                    if (e.shiftKey) {
                        // If shift key is pressed (while pressing tab), move focus to the last focusable element
                        const index = focusableElements.indexOf(activeElement);
                        const previousElement =
                            focusableElements[index - 1] || lastFocusable;
                        previousElement.focus();
                    } else {
                        // If tab key is pressed and Shift key is not pressed,
                        // move focus to the first focusable element
                        const index = focusableElements.indexOf(activeElement);
                        const nextElement =
                            focusableElements[index + 1] || firstFocusable;
                        nextElement.focus();
                    }
                } else if (e.key === "Escape") {
                    // Close the modal on Escape key
                    closeHandler();
                }
            };

            element.addEventListener("keydown", handleKeyDown);

            // Cleanup event listener on unmount
            return () => {
                element.removeEventListener("keydown", handleKeyDown);
            };
        }
    }, [closeHandler]);

    return ref;
};
