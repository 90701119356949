import React, { useEffect } from "react";
import styles from "./EndOfReportOptions.module.css";
import { useFlags } from "../../../contexts/FlagsProvider/FlagsProvider";
import FlagsEnum from "../../../utils/flagsEnum";
import {
    finalizeReport,
    finalizeReportWithToken,
} from "../../../utils/reportUtils";
import { MessageAuthor, MessageType } from "../../Chatbot/ChatbotModal";
import { useMsal } from "@azure/msal-react";
import { scrollToBottomOfChat } from "../../../utils/chatUtils";

type MessageOptionsType = ({
    restartReport,
    message,
    addMessage,
    setChatbotThinking,
}: {
    restartReport: () => void;
    message: MessageType;
    addMessage: (message: MessageType) => void;
    setChatbotThinking: (isThinking: boolean) => void;
}) => React.ReactElement | null;

const RETURN_TO_REPORT_IT_TEXT = "Return to the Report It homepage";
export const EndOfReportOptions: MessageOptionsType = ({
    restartReport,
    message,
    addMessage,
    setChatbotThinking,
}) => {
    const wasteReportsEndpoint = useFlags(FlagsEnum.WasteReportsEndpoint);
    const reportItHomepage = useFlags(FlagsEnum.ReportItHomepage);
    const myWestminsterHomePage = useFlags(FlagsEnum.MyWestminsterHomepage);

    const { accounts, instance } = useMsal();
    const isUserLoggedIn = accounts.length > 0;

    const successOptions = [
        {
            text: "Submit another report",
            onClick: restartReport,
        },
    ];

    const sucessOptionLoggedIn = isUserLoggedIn
        ? [
              {
                  text: "Return to MyWestminster",
                  onClick: () => {
                      window.open(myWestminsterHomePage, "_blank");
                  },
              },
          ]
        : [];

    const failOptions = [
        {
            text: "Retry",
            onClick: () => {
                if (isUserLoggedIn) {
                    finalizeReportWithToken(
                        wasteReportsEndpoint,
                        addMessage,
                        setChatbotThinking,
                        accounts,
                        instance,
                    );
                } else {
                    finalizeReport(
                        wasteReportsEndpoint,
                        addMessage,
                        setChatbotThinking,
                    );
                }
            },
        },
    ];

    if (reportItHomepage) {
        failOptions.push({
            text: RETURN_TO_REPORT_IT_TEXT,
            onClick: () => {
                window.open(reportItHomepage, "_blank");
            },
        });
        successOptions.push(
            {
                text: RETURN_TO_REPORT_IT_TEXT,
                onClick: () => {
                    window.open(reportItHomepage, "_blank");
                },
            },
            ...sucessOptionLoggedIn,
        );
    }

    useEffect(() => {
        scrollToBottomOfChat();
    }, []);

    const options =
        message.author === MessageAuthor.GiveFeedbackMessage
            ? successOptions
            : failOptions;
    return (
        <div className={styles.messageOptions}>
            <div className={styles.optionsContainer}>
                {options.map((option, index) => {
                    return (
                        <button
                            className={styles.suggestion}
                            key={index}
                            onClick={option.onClick}
                        >
                            {option.text}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};
