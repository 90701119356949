import React, { useEffect } from "react";
import { MessageAuthor, MessageType } from "../ChatbotModal";
import styles from "./Messages.module.css";
import { UploadedImage } from "../../CustomMessages/UploadedImage";
import { ImageUpload } from "../../CustomMessages/ImageUpload";
import { ChatbotThinking } from "../ChatbotThinking";
import { MessageOptions } from "../../CustomMessages/MessageOptions";
import { ScreenReaderOnly } from "../../ScreenReaderOnly";
import avatar from "../../../styles/assets/avatar.svg";
import { scrollToBottomOfChat } from "../../../utils/chatUtils";
import { Map } from "../../CustomMessages/Map";
import { Confirmation } from "../../CustomMessages/Confirmation";
import { EndOfReportOptions } from "../../CustomMessages/EndOfReportOptions";
import { SubmitOptions } from "../../CustomMessages/SubmitOptions";
import { GuestForm } from "../../CustomMessages/GuestForm";
import { UserDetails } from "../../../types/dataTypes";
import { useFlags } from "../../../contexts/FlagsProvider/FlagsProvider";
import FeatureFlags from "../../../utils/flagsEnum";
import { useMsal } from "@azure/msal-react";

type MessagesType = ({
    messages,
    addMessage,
    setUserDetails,
    chatbotThinking,
    setChatbotThinking,
    setLightboxOpen,
    addUploadedImageMessage,
    locationSaved,
    setLocationSaved,
    setSubmitOption,
    restartReport,
    customMessageHandler,
    userDetailsCollected,
}: {
    messages: MessageType[];
    addMessage: (message: MessageType) => void;
    setUserDetails: (details: UserDetails) => void;
    setChatbotThinking: (thinking: boolean) => void;
    chatbotThinking: boolean;
    setLightboxOpen: (lightboxOpen: boolean) => void;
    addUploadedImageMessage: (message: MessageType) => void;
    locationSaved: boolean;
    setLocationSaved: (locationSaved: boolean) => void;
    restartReport: () => void;
    customMessageHandler?: (userMessage: string) => void;
    setSubmitOption: ({
        value,
        displayText,
    }: {
        value: string;
        displayText: string;
    }) => void;
    userDetailsCollected: boolean;
}) => React.ReactElement | null;

export const Messages: MessagesType = ({
    messages,
    addMessage,
    setUserDetails,
    setChatbotThinking,
    chatbotThinking,
    setLightboxOpen,
    addUploadedImageMessage,
    locationSaved,
    setLocationSaved,
    restartReport,
    customMessageHandler,
    setSubmitOption,
    userDetailsCollected,
}) => {
    const lastMessageRef = React.useRef<HTMLDivElement>(null);

    // When messages update, scroll to the bottom of the messages container
    // TODO sometimes when you refresh the page this does not scroll to the bottom of the messages container
    useEffect(() => {
        // Focus on the last message (for screen reader users)
        if (lastMessageRef.current) {
            lastMessageRef.current.focus();
        }
        scrollToBottomOfChat();
    }, [chatbotThinking, messages]);

    const lastMessage = messages ? messages.at(-1) : null;
    const govMetricsSurveyUrl = useFlags(FeatureFlags.GovMetricUrl);

    const { accounts } = useMsal();
    const isNotLoggedIn = accounts.length === 0;

    const getMessageClass = (isUser: boolean) => {
        return [
            "message",
            styles.message,
            isUser
                ? [styles.userMessage, styles.speechBubbleUser].join(" ")
                : styles.speechBubbleChatbot,
        ].join(" ");
    };

    const getChatbotMessage = (
        message: MessageType,
        index: number,
        customMessageComponent?: React.ReactElement,
    ) => {
        return (
            <div key={index} className={styles.chatbotMessageContainer}>
                <div className={styles.chatbotMessage}>
                    <img alt="" src={avatar} className={styles.avatar} />

                    <div
                        className={getMessageClass(false)}
                        ref={
                            index === messages.length - 1
                                ? lastMessageRef
                                : null
                        }
                    >
                        {customMessageComponent || (
                            <p>
                                <ScreenReaderOnly tag="span">Chatbot message: </ScreenReaderOnly>{message.displayText || message.text}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const imageUploadDisclaimerUrl = useFlags(
        FeatureFlags.ImageUploadDisclaimerUrl,
    );

    const getImageDisclaimerMessage = () => {
        return (
            <p>
                Find out{" "}
                <a
                    className={styles.disclaimerText}
                    rel="noreferrer"
                    target="_blank"
                    href={imageUploadDisclaimerUrl}
                >
                    how we will be using your image
                </a>
            </p>
        );
    };

    const getEndOfReportOptions = (message: MessageType) => {
        return (
            <>
                {lastMessage === message ? (
                    <EndOfReportOptions
                        message={message}
                        restartReport={restartReport}
                        addMessage={addMessage}
                        setChatbotThinking={setChatbotThinking}
                    />
                ) : null}
            </>
        );
    };

    const getReportStatusMessage = (message: MessageType) => {
        return (
            <>
                <div className={styles.chatbotMessageContainer}>
                    <div className={styles.chatbotMessage}>
                        <img
                            alt="avatar"
                            src={avatar}
                            className={styles.avatar}
                        />
                        <div className={getMessageClass(false)}>
                            <Confirmation message={message} />
                        </div>
                    </div>
                </div>
                {getEndOfReportOptions(message)}
            </>
        );
    };

    const getGiveFeedbackMessage = (message: MessageType) => {
        return (
            <>
                <div className={styles.chatbotMessageContainer}>
                    <div className={styles.chatbotMessage}>
                        <img
                            alt="avatar"
                            src={avatar}
                            className={styles.avatar}
                        />
                        <div className={getMessageClass(false)}>
                            <p>
                                Let us know how we did by completing this{" "}
                                <a
                                    href={govMetricsSurveyUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    feedback form
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                {getEndOfReportOptions(message)}
            </>
        );
    };

    const getMessageComponent = (message: MessageType, index: number) => {
        switch (message.author) {
            case MessageAuthor.Chatbot:
            case MessageAuthor.ImageClassification:
            case MessageAuthor.ExtraLocationDetails:
            case MessageAuthor.ExtraReportDetails:
            case MessageAuthor.IntroMessage:
                return (
                    <div key={index}>
                        {/* The chatbot sends a message with completedForm: true,
                            but we don't need to display it to the user because
                            we may need to record the location first
                        */}
                        {!message.completedForm
                            ? getChatbotMessage(message, index)
                            : null}
                    </div>
                );
            case MessageAuthor.ReportCreatedMessage:
            case MessageAuthor.ReportErrorMessage:
                return getReportStatusMessage(message);
            case MessageAuthor.GiveFeedbackMessage:
                return getGiveFeedbackMessage(message);
            case MessageAuthor.User:
            case MessageAuthor.LocationDetailsAnswer:
            case MessageAuthor.ReportDetailsAnswer:
                return (
                    <div className={getMessageClass(true)} key={index}>
                        <ScreenReaderOnly tag="p" role="none">Your message: </ScreenReaderOnly>
                        {message.displayText || message.text}
                    </div>
                );
            case MessageAuthor.ImageDisclaimer:
                return getChatbotMessage(
                    message,
                    index,
                    getImageDisclaimerMessage(),
                );
            case MessageAuthor.ImageUpload:
                return (
                    <ImageUpload
                        key={index}
                        chatbotThinking={chatbotThinking}
                        setChatbotThinking={setChatbotThinking}
                        addUploadedImageMessage={addUploadedImageMessage}
                        addMessage={addMessage}
                    />
                );
            case MessageAuthor.UploadedImage:
                return (
                    <UploadedImage
                        key={index}
                        message={message}
                        index={index}
                        onImageClicked={() => {
                            setLightboxOpen(true);
                        }}
                    />
                );
            case MessageAuthor.Location:
                return (
                    <div key={index}>
                        {getChatbotMessage(message, index)}
                        <div className={styles.mapContainer}>
                            <Map
                                message={message}
                                locationSaved={locationSaved}
                                setLocationSaved={setLocationSaved}
                            />
                        </div>
                    </div>
                );

            case MessageAuthor.SubmitOptions:
                return (
                    <div key={index}>
                        {isNotLoggedIn
                            ? getChatbotMessage(message, index)
                            : null}

                        {lastMessage?.author === MessageAuthor.SubmitOptions ? (
                            <SubmitOptions
                                setUserDetails={setUserDetails}
                                setSubmitOption={setSubmitOption}
                                userDetailsCollected={userDetailsCollected}
                            />
                        ) : null}
                    </div>
                );
            case MessageAuthor.FormSubmitUser:
                return (
                    <div key={index}>
                        {getChatbotMessage(message, index)}
                        <GuestForm
                            addMessage={addMessage}
                            setUserDetails={setUserDetails}
                        />
                    </div>
                );
        }
    };

    return (
        <div id="messagesContainer" className={styles.messagesContainer}>
            {messages.map((message, index) =>
                !message?.hidden ? getMessageComponent(message, index) : null,
            )}

            {chatbotThinking ? (
                <ChatbotThinking />
            ) : (lastMessage?.author === MessageAuthor.Chatbot ||
                  lastMessage?.author === MessageAuthor.ImageClassification ||
                  lastMessage?.author === MessageAuthor.ExtraLocationDetails ||
                  lastMessage?.author === MessageAuthor.ExtraReportDetails) &&
              (lastMessage?.options || lastMessage?.advancedOptions) ? (
                <MessageOptions
                    addMessage={addMessage}
                    message={lastMessage}
                    chatbotThinking={chatbotThinking}
                    setChatbotThinking={setChatbotThinking}
                    fullWidthOnMobile={true}
                    customMessageHandler={customMessageHandler}
                />
            ) : null}
        </div>
    );
};
