import React, { useEffect } from "react";
import { Button } from "../../Button";
import styles from "./GuestForm.module.css";
import { scrollToBottomOfChat } from "../../../utils/chatUtils";
import { UserDetails } from "../../../types/dataTypes";
import {
    MessageAuthor,
    MessageType,
    UserMessageSource,
} from "../../Chatbot/ChatbotModal";
import { useFlags } from "../../../contexts/FlagsProvider/FlagsProvider";
import FeatureFlags from "../../../utils/flagsEnum";

type GuestFormType = ({
    addMessage,
    setUserDetails,
}: {
    addMessage: (message: MessageType) => void;
    setUserDetails: (details: UserDetails) => void;
}) => React.ReactElement | null;

export const GuestForm: GuestFormType = ({ addMessage, setUserDetails }) => {
    const DataProtectionLink = useFlags(FeatureFlags.DataProtectionLink);

    const [isSubmited, setIsSubmited] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>("");
    const [formFields, setFormFields] = React.useState({
        firstName: "",
        lastName: "",
        email: "",
    });

    const disableSubmitButton = !(
        formFields.firstName &&
        formFields.lastName &&
        formFields.email &&
        !error
    );

    const textToDisplay = `First name: ${formFields.firstName}\nLast name: ${formFields.lastName}\nEmail: ${formFields.email} `;

    useEffect(() => {
        scrollToBottomOfChat();
    });

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.id) {
            setError("");

            setFormFields({
                ...formFields,
                [e.target.id]: e.target.value,
            });
        }
    };

    const submitForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // regex source: https://www.w3resource.com/javascript/form/email-validation.php
        let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        e.preventDefault();

        if (!regex.test(formFields.email)) {
            setError("Please enter a valid email");
            setIsSubmited(false);
            return;
        }

        setIsSubmited(true);

        setUserDetails({
            ...formFields,
            isAnon: false,
        });

        addMessage({
            text: "user details",
            displayText: textToDisplay,
            author: MessageAuthor.User,
            userMessageSource: UserMessageSource.TextInput,
        });
    };

    return (
        <form aria-label="user details" autoComplete="off">
            <div className={styles.fullNameWrapper}>
                <input
                    type="text"
                    id="firstName"
                    required
                    readOnly={isSubmited}
                    name="firstName"
                    maxLength={50}
                    placeholder="First name*"
                    onChange={handleInput}
                />
                <input
                    type="text"
                    id="lastName"
                    required
                    readOnly={isSubmited}
                    name="lastName"
                    maxLength={100}
                    placeholder="Last name*"
                    onChange={handleInput}
                />
            </div>

            <div className={styles.emailWrapper}>
                <input
                    type="email"
                    id="email"
                    required
                    aria-label={error ? error : ""}
                    readOnly={isSubmited}
                    name="email"
                    placeholder="Email*"
                    onChange={handleInput}
                />
            </div>

            {error && (
                <p aria-live="assertive" className={styles.invalidEmail}>
                    {error}
                </p>
            )}

            <div className={styles.disclaimerMessageWrapper}>
                {/* hide label for screen readers and add label inside the link, 
                gives the user a better UX - reads everything in one block */}

                <span className={styles.disclaimerMessage} aria-hidden>
                    If you would like to know more about how the council
                    processes your data, please see
                </span>

                <a
                    href={DataProtectionLink}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="If you would like to know more about how the council processes your data, please see Westminster City Council's data protection notice."
                >
                    Westminster City Council's data protection notice.
                </a>
            </div>

            {!isSubmited && (
                <Button
                    text="Confirm details"
                    variant="secondary"
                    aria-label={
                        disableSubmitButton ? "Please fill in all fields" : ""
                    }
                    disabled={disableSubmitButton}
                    aria-disabled={disableSubmitButton}
                    onClick={submitForm}
                />
            )}
        </form>
    );
};
