import React, { useEffect, useRef, useState } from "react";
import styles from "./Map.module.css";
import { Coordinates } from "../../../types/dataTypes";
import { MessageType } from "../../Chatbot/ChatbotModal";
import { ImageLocation } from "../ImageUpload";
import {
    getAddress,
    getLocationFromSessionStorage,
    parseAndStoreGeocodeResponse,
    scrollToBottomOfChat,
    setLocationSavedInSessionStorage,
    setReportLocation,
} from "../../../utils/chatUtils";
import geojson from "../../../utils/WCC_Boundary.json";
import {
    GoogleMap,
    Libraries,
    MarkerF,
    useJsApiLoader,
    Autocomplete,
} from "@react-google-maps/api";
import { useFlags } from "../../../contexts/FlagsProvider/FlagsProvider";
import FeatureFlags from "../../../utils/flagsEnum";
import LocationIcon from "../../../styles/assets/location-crosshairs-solid.svg";
import { Button } from "../../Button";

import { locationToAddress } from "@arcgis/core/rest/locator";
import { addNewPropertiesToFormattedReport } from "../../../utils/reportUtils";
import { WasteReport } from "../../../types/wasteReportTypes";

type MapType = ({
    message,
    locationSaved,
    setLocationSaved,
}: {
    message: MessageType;
    locationSaved: boolean;
    setLocationSaved: (locationSaved: boolean) => void;
}) => React.ReactElement | null;

const fullWidthContainerStyle: React.CSSProperties = {
    width: "100%",
    borderRadius: "8px",
    transition: "height 0.5s",
    height: "300px",
};

// needs to be outside of component to prevent re-rendering
const libs: Libraries = ["places"];

const noUSRNOrUPRNMessage =
    "We cannot retrieve an address and may not provide waste related services for this location. If you want to try again, please select a different location.";
const unableToRetrieveCurrentLocationMessage =
    "An error occurred and we were unable to get your location. Please try again, or enter the location using another method";
const outsideWestminsterMessage =
    "Please select a location inside Westminster area.";

export const Map: MapType = ({ locationSaved, setLocationSaved }) => {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_MAPS_KEY || "",
        libraries: libs,
    });
    const [geojsonPolygons, setGeojsonPolygons] = useState<
        google.maps.Polygon[]
    >([]);

    const westminsterCenterCoord = useFlags(FeatureFlags.MapCenterCoordinates);
    const polygonColor = useFlags(FeatureFlags.PolygonColor) || "#6631FF";

    const esriEndpoint = useFlags(FeatureFlags.EsriEndpoint);

    const [map, setMap] = React.useState<google.maps.Map | null>(null);
    const [currentLocationLoading, setCurrentLocationLoading] =
        React.useState(false);
    const [isCurrentLocationOff, setIsCurrentLocationOff] =
        React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>("");
    const [selectedPlace, setSelectedPlace] = React.useState<
        ImageLocation | undefined
    >();

    const searchBoxInputRef = React.useRef<HTMLInputElement | null>(null); // to clear search box input
    const searchBoxRef = React.useRef<google.maps.places.Autocomplete | null>(
        null,
    );

    const geojsonPolygonRef = useRef<google.maps.Polygon[] | null>();

    const locationSavedRef = useRef(locationSaved);
    useEffect(() => {
        locationSavedRef.current = locationSaved;
    }, [locationSaved]);

    useEffect(() => {
        geojsonPolygons.forEach((polygon) => {
            polygon.setMap(map);
        });
        geojsonPolygonRef.current = geojsonPolygons;

        return () => {
            geojsonPolygons.forEach((polygon) => {
                polygon.setMap(null);
            });
        };
    }, [geojsonPolygons, map]);

    const isPlaceInWestminster = (location: ImageLocation): boolean => {
        let isInside = null;
        if (isLoaded && geojsonPolygonRef.current) {
            const locationLatLng = new window.google.maps.LatLng(
                Number(location.latitude),
                Number(location.longitude),
            );
            isInside = geojsonPolygonRef.current.some((geojsonPolygon) => {
                return window.google.maps.geometry.poly.containsLocation(
                    locationLatLng,
                    geojsonPolygon,
                );
            });
        }
        return isInside ?? false;
    };

    const getSelectedPlaceFromSessionStorage = ():
        | ImageLocation
        | undefined => {
        // Only load the location from session storage if selectedPlace is not already set
        if (!selectedPlace) {
            const locationParsed = JSON.parse(
                getLocationFromSessionStorage() || "{}",
            );
            const isInsideBounds = isPlaceInWestminster(locationParsed);
            if (isInsideBounds) {
                setSelectedPlace({
                    latitude: locationParsed.latitude,
                    longitude: locationParsed.longitude,
                    address: locationParsed.address,
                });

                return locationParsed;
            }
        }

        return undefined;
    };

    const onMapLoad = (map: google.maps.Map) => {
        setMap(map);
        setGeojsonPolygons(
            geojson.features.map((feature) => {
                const polygon = new window.google.maps.Polygon({
                    paths: feature.geometry.coordinates.map((coordinate) => {
                        return coordinate.map((latLngPair) => {
                            return new window.google.maps.LatLng(
                                latLngPair[1],
                                latLngPair[0],
                            );
                        });
                    }),
                    fillOpacity: 0.1,
                    fillColor: polygonColor,
                    strokeColor: polygonColor,
                    strokeWeight: 1.5,
                    strokeOpacity: 0.6,
                    clickable: true,
                });
                google.maps.event.addListener(
                    polygon,
                    "click",
                    (event: google.maps.PolyMouseEvent) => {
                        onPolygonClick(event);
                    },
                );
                return polygon;
            }),
        );
    };

    const onAutocompleteLoad = (
        autocomplete: google.maps.places.Autocomplete,
    ) => {
        searchBoxRef.current = autocomplete;
        scrollToBottomOfChat();
    };

    const handleLocationAddress = (
        location: ImageLocation,
        address?: string,
    ) => {
        setErrorMessage(null);
        if (
            location?.latitude &&
            location?.longitude &&
            searchBoxInputRef.current
        ) {
            const isInsideBounds = isPlaceInWestminster({
                latitude: location.latitude,
                longitude: location.longitude,
            });
            setErrorMessage(isInsideBounds ? null : outsideWestminsterMessage);

            if (isInsideBounds) {
                const latitude = location.latitude;
                const longitude = location.longitude;

                if (!address) {
                    getAddress({ latitude, longitude }).then((address) => {
                        if (address) {
                            setReportLocation(latitude, longitude, address);
                            setSelectedPlace({ latitude, longitude, address });
                        }
                    });
                } else {
                    setReportLocation(latitude, longitude, address);
                    setSelectedPlace({ latitude, longitude, address });
                }
                searchBoxInputRef.current.value = address || "";
            } else {
                setReportLocation();
                setSelectedPlace(undefined);
                searchBoxInputRef.current.value = "";
            }
        } else {
            console.error("This is not in Westminster");
            setReportLocation();
            setSelectedPlace(undefined);
        }
        focusAddress();
    };

    const focusAddress = () => {
        const addressSearchBox = document.getElementById("address");
        if (addressSearchBox) {
            addressSearchBox.focus();
        }
    };

    const getCurrentLocation = () => {
        setErrorMessage(null);
        setCurrentLocationLoading(true);

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const latitude = position.coords.latitude.toString();
                const longitude = position.coords.longitude.toString();
                handleLocationAddress({ latitude, longitude });
                setCurrentLocationLoading(false);
                setIsCurrentLocationOff(false);
                focusAddress();
            },
            (error) => {
                console.error(error);
                setErrorMessage(unableToRetrieveCurrentLocationMessage);
                setCurrentLocationLoading(false);
                setIsCurrentLocationOff(true);
                setSelectedPlace(undefined);
            },
        );
        focusAddress();
    };

    const onPlaceChanged = () => {
        if (searchBoxRef.current) {
            const place = searchBoxRef.current.getPlace();

            if (place?.address_components) {
                parseAndStoreGeocodeResponse(place.address_components);
            }
            const location = place?.geometry?.location;
            const address = place?.formatted_address;
            if (location && address) {
                const latitude = location.lat().toString();
                const longitude = location.lng().toString();
                handleLocationAddress({ latitude, longitude }, address);
            } else {
                handleLocationAddress({ latitude: "", longitude: "" });
                console.error("Place not found");
            }
        }
    };

    const onMapClick = () => {
        if (!locationSaved) {
            setSelectedPlace(undefined);
            handleLocationAddress({ latitude: "", longitude: "" });
        }

        // clean search box input - outside polygon
        if (searchBoxInputRef.current) {
            searchBoxInputRef.current.value = "";
        }

        scrollToBottomOfChat();
    };

    const onPolygonClick = (e: google.maps.PolyMouseEvent) => {
        if (!locationSavedRef.current && e.latLng) {
            const clickedLocation: ImageLocation = {
                latitude: e.latLng.lat().toString(),
                longitude: e.latLng.lng().toString(),
            };

            handleLocationAddress(clickedLocation);
            setSelectedPlace(clickedLocation);
        }
    };

    const [center, setCenter] = useState<Coordinates | undefined>();

    // set center of map to be the selectedPlace
    useEffect(() => {
        // Assign the new center to a temporary value
        // to avoid a re-render loop or a missing dependency
        const c =
            selectedPlace?.latitude && selectedPlace.longitude
                ? {
                      lat: Number(selectedPlace.latitude),
                      lng: Number(selectedPlace.longitude),
                  }
                : undefined;
        setCenter(c);
        if (c) {
            window.setTimeout(() => {
                map?.panTo(c);
            }, 50);
        }
    }, [selectedPlace, map]);

    const getAddressFromLocation = async (
        latitude?: number | null,
        longitude?: number | null,
    ): Promise<Record<string, any> | null> => {
        const point = {
            latitude: latitude,
            longitude: longitude,
        };

        try {
            const response = await locationToAddress(esriEndpoint, {
                /* We are using the locationToAddress function as suggested by WCC
                   based on their implementation of the same functionality.
                   This method uses undocumented types and thus produces a type error,
                   but it does in fact work.
                @ts-expect-error  */
                location: point,
            });
            return response;
        } catch (error) {
            console.error(error);
        }
        return null;
    };

    const saveLocation = () => {
        if (!errorMessage) {
            const latNumber = Number(selectedPlace?.latitude);
            const lngNumber = Number(selectedPlace?.longitude);
            setLoading(true);
            getAddressFromLocation(latNumber, lngNumber).then(
                (response) => {
                    if (
                        response?.attributes?.UPRN &&
                        response?.attributes?.USRN
                    ) {
                        const addressDetails: Partial<WasteReport> = {
                            ServiceDeliveryLocationDetailsObject: {
                                ServiceLocationDetailsObject: {
                                    latitude: latNumber,
                                    longitude: lngNumber,
                                    UPRN: response?.attributes?.UPRN,
                                    USRN: response?.attributes?.USRN,
                                },
                            },
                        };
                        addNewPropertiesToFormattedReport(addressDetails);
                        setLocationSaved(true);
                        setLocationSavedInSessionStorage(true);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setSelectedPlace(undefined);
                        setErrorMessage(noUSRNOrUPRNMessage);
                    }
                },
                (error) => {
                    console.error(error);
                    setLoading(false);
                    setSelectedPlace(undefined);
                    setErrorMessage(noUSRNOrUPRNMessage);
                },
            );
        }
    };

    if (!isLoaded) return <p>Error loading map</p>;

    return (
        <>
            <div className={styles.mapContainer} data-testid="map-container">
                <div className={styles.topInputs}>
                    <Autocomplete
                        className={styles.autocomplete}
                        onLoad={onAutocompleteLoad}
                        onPlaceChanged={onPlaceChanged}
                        restrictions={{ country: "uk" }}
                    >
                        <input
                            disabled={locationSaved}
                            type="text"
                            defaultValue={selectedPlace?.address}
                            id="addressSearchBox"
                            placeholder="Search for an address"
                            ref={searchBoxInputRef}
                            className={styles.searchBox}
                        />
                    </Autocomplete>
                </div>

                <button
                    onClick={getCurrentLocation}
                    disabled={locationSaved}
                    className={styles.useCurrentLocationContainer}
                >
                    <p className={styles.useCurrentLocation}>
                        {currentLocationLoading
                            ? "Loading..."
                            : isCurrentLocationOff
                            ? "Turn on location services"
                            : "Use current location"}
                    </p>
                    <img className={styles.icon} src={LocationIcon} alt="" />
                </button>

                <GoogleMap
                    onTilesLoaded={getSelectedPlaceFromSessionStorage}
                    onLoad={onMapLoad}
                    mapContainerStyle={fullWidthContainerStyle}
                    center={westminsterCenterCoord}
                    onClick={!locationSaved ? onMapClick : undefined}
                    zoom={12}
                    options={{
                        disableDefaultUI: locationSaved,
                        draggable: !locationSaved,
                        clickableIcons: false,
                        // In order to overlay things on the map
                        // we need to remove the controls built into the map
                        fullscreenControl: false,
                        streetViewControl: false,
                        zoomControl: false,
                        mapTypeControl: false,
                        minZoom: 12,
                        maxZoom: 18,
                        keyboardShortcuts: false,
                    }}
                >
                    {center && <MarkerF position={center} />}
                </GoogleMap>
            </div>
            <div
                id="address"
                tabIndex={0}
                className={
                    errorMessage
                        ? [styles.address, styles.error].join(" ")
                        : styles.address
                }
                aria-live="assertive"
            >
                {errorMessage
                    ? errorMessage
                    : selectedPlace?.address
                    ? selectedPlace?.address
                    : "No address selected"}
            </div>
            {!locationSaved && (
                <Button
                    variant="primary"
                    text="Confirm location"
                    onClick={saveLocation}
                    disabled={
                        !!errorMessage || !selectedPlace?.address || loading
                    }
                />
            )}
        </>
    );
};
