enum FeatureFlags {
    MapCenterCoordinates = "map_center_coordinates",
    BinBagDetectorThreshold = "bin_bag_detector_threshold",
    ClassificationThreshold = "classification_confidence_threshold",
    PolygonColor = "polygon_color",
    EsriEndpoint = "esri_endpoint",
    WasteReportsEndpoint = "waste_reports_endpoint",
    GovMetricUrl = "gov_metric_url",
    ReportItHomepage = "report_it_homepage",
    MyWestminsterHomepage = "My_Westminster_Home",
    CategoryMapping = "category_mapping",
    DataProtectionLink = "data_protection_info",
    ImageUploadDisclaimerUrl = "image_upload_disclaimer_url",
}

export default FeatureFlags;
