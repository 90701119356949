import React from "react";
import { initFirebase } from "../../utils/firebaseUtils";
import {
    getRemoteConfig,
    fetchAndActivate,
    getAll,
    RemoteConfig,
} from "firebase/remote-config";

type Settings = {
    [key: string]: any;
};
type SettingsContextValue = Settings;

export const SettingsContext = React.createContext<SettingsContextValue>({});

// inspiration from: https://www.machinemax.io/articles/feature-flags
export function useFlags(flagKey: string) {
    const allFlags = React.useContext(SettingsContext);
    return allFlags[flagKey];
}

export const FlagsProvider: React.FC<React.PropsWithChildren<{}>> = (props) => {
    const [flags, setFlags] = React.useState<Settings>({});
    const app = initFirebase();
    let config: RemoteConfig = getRemoteConfig(app) || null;

    // TODO this is a short fetch interval for demo purposes
    // In production app we would want to set this higher
    config.settings.minimumFetchIntervalMillis = 5000;

    React.useEffect(() => {
        const fetchFlags = async () => {
            try {
                await fetchAndActivate(config);
                const remoteFlags = getAll(config);
                const newFlags: Settings = {};

                for (const [key, item] of Object.entries(remoteFlags)) {
                    const value = item.asString();
                    try {
                        newFlags[key] = JSON.parse(value);
                    } catch (error) {
                        // If the value is not JSON, just set it as a string
                        newFlags[key] = value;
                    }
                }
                setFlags(newFlags);
            } catch (error) {
                console.error(
                    "Error fetching and activating remote config:",
                    error,
                );
            }
        };

        fetchFlags();
    }, [config]);

    return (
        <SettingsContext.Provider value={flags}>
            {props.children}
        </SettingsContext.Provider>
    );
};

export default FlagsProvider;
