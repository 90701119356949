import { getApp, getApps, initializeApp } from "firebase/app";
import {
    Functions,
    connectFunctionsEmulator,
    getFunctions,
} from "firebase/functions";
import "firebase/remote-config";
import { FirebaseStorage, getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};

export const initFirebase = () => {
    if (getApps().length === 0) {
        return initializeApp(firebaseConfig);
    } else {
        return getApp();
    }
};

export const RECORD_DATA_FUNCTION_NAME = "record_data";
export const GET_CHATBOT_MESSAGE_FUNCTION_NAME = "get_chatbot_message";
export const IMAGE_CLASSIFICATION_FUNCTION_NAME = "images_classification";

export const getFirebaseStorage = (): FirebaseStorage => {
    const app = initFirebase();
    return getStorage(app);
};

export const getFirebaseFunctions = (): Functions => {
    const app = initFirebase();
    const functions = getFunctions(app, "europe-west2");
    // If running locally (via `firebase emulators:start` or `npm run start`) then
    // point the functions to the local emulator
    if (
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1"
    ) {
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }
    return functions;
};
