import styles from "./ChatbotModal.module.css";
import React, { useEffect, useState } from "react";
import minimizeIcon from "../../../styles/assets/minimize.svg";
import { Messages } from "../Messages";
import { UserInput } from "../UserInput";
import {
    setReportLocation,
    generateNewSessionId,
    getChatHistory,
    getInitialMessages,
    getLocationFromSessionStorage,
    setChatHistory,
    getLocationSavedInSessionStorage,
    setLocationSavedInSessionStorage,
    getReportCategory,
    setReportCategory,
} from "../../../utils/chatUtils";
import refreshIcon from "../../../styles/assets/refresh.svg";
import { RestartReport } from "../RestartReport";
import { useFlags } from "../../../contexts/FlagsProvider/FlagsProvider";
import { Lightbox } from "../Lightbox";
import {
    getEndMetricsSent,
    getStartMetricsSent,
    recordConversationEnd,
    recordConversationStart,
    setEndMetricsSent,
    setStartMetricsSent,
} from "../../../utils/metricUtils";
import { SubmitOptionType, UserDetails } from "../../../types/dataTypes";
import {
    addNewPropertiesToFormattedReport,
    finalizeReport,
    finalizeReportWithToken,
    resetFormattedReport,
    setReportProgress,
} from "../../../utils/reportUtils";
import FeatureFlags from "../../../utils/flagsEnum";
import SubmitReportOptions from "../../../utils/submitOptionsEnum";
import { WasteReport } from "../../../types/wasteReportTypes";
import { useMsal } from "@azure/msal-react";

type ChatbotModalType = ({
    closeModal,
}: {
    closeModal: () => void;
}) => React.ReactElement | null;

export enum MessageAuthor {
    User,
    Chatbot,
    ImageUpload,
    UploadedImage,
    Location,
    ImageClassification,
    ReportCreatedMessage,
    ReportErrorMessage,
    ExtraLocationDetails,
    LocationDetailsAnswer,
    ExtraReportDetails,
    ReportDetailsAnswer,
    SubmitOptions,
    FormSubmitUser,
    GiveFeedbackMessage,
    IntroMessage,
    ImageDisclaimer,
}

// If the message came from a user, we need to know how they sent it
// (e.g. By uploading an image, typing a message, clicking on a message option)
export enum UserMessageSource {
    ImageUpload,
    TextInput,
}

type AdvancedOption = {
    text: string;
    value: string;
};

export type MessageType = {
    author: MessageAuthor;
    text: string;
    userMessageSource?: UserMessageSource;
    imageUrl?: string;
    classification?: string | null;
    options?: string[];
    // TODO this could be refactored to be the same as options
    //  but it can't be done unless the chatbot also returns this new format
    //  so the chatbot needs to be updated too
    advancedOptions?: AdvancedOption[];
    displayText?: string;
    completedForm?: boolean;
    category?: string;
    hidden?: boolean;
    confirmationFields?: {
        CAS: string;
        SLA: string;
        category: string;
        emailAddress?: string;
    };
};

const EXTRA_DETAIL_MESSAGE_MAX_LENGTH = 300;

export type ChatHistoryType = MessageType[];

const addLocationMessageText =
    "Please select the location for the issue. You can do this by clicking on the map, searching for an address or using your current location";

export const ChatbotModal: ChatbotModalType = ({
    closeModal,
}): React.ReactElement => {
    const initialMessagesLength = getInitialMessages().length;
    const existingMessages = getChatHistory();
    const [messages, setMessages] = useState<ChatHistoryType>(existingMessages);
    const [userDetails, setUserDetails] = useState<UserDetails>();
    const [restartReportOpen, setRestartReportOpen] = useState<boolean>(false);
    const [chatbotThinking, setChatbotThinking] = useState<boolean>(false);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [locationSaved, setLocationSaved] = useState(
        getLocationSavedInSessionStorage(),
    );

    const [submitOption, setSubmitOption] = useState<SubmitOptionType | null>(
        null,
    );

    const questionsWithMaxInputLength = [
        MessageAuthor.ExtraLocationDetails,
        MessageAuthor.ExtraReportDetails,
    ];

    useEffect(() => {
        // store the user details in the formatted report
        if (userDetails) {
            const emailAddressObject = userDetails?.email
                ? [
                      {
                          // We do not collect the type of the email address
                          // so we set it to "H" which is the example used in the API docs
                          type: "H",
                          email_address: userDetails?.email,
                      },
                  ]
                : undefined;
            const userDetailsObject: Partial<WasteReport> = {
                PrimaryConsumerDetailsObject: {
                    NameDetailsObject: {
                        first_name: userDetails?.firstName,
                        last_name: userDetails?.lastName,
                    },
                    consumer_id: userDetails?.consumerId,
                    // The NameDetailsObject can be empty and it won't matter
                    // but the API will reject an empty ContactEmailDetailsObject
                    // so either include it or set it to undefined
                    ContactEmailDetailsObject: emailAddressObject,
                },
                ServiceRegistrationDetailsObject: {
                    anonymous: userDetails?.isAnon || false,
                    // In other applications, this field might not directly map to whether we have the email address or not
                    // but in this case, we do not ask the user for their contact preferences, so it can always be true
                    // if we have the email address, and false if we do not
                    consumer_contact_primary: !!userDetails?.email,
                },
            };
            addNewPropertiesToFormattedReport(userDetailsObject);
        }
    }, [userDetails]);

    const wasteReportsEndpoint = useFlags(FeatureFlags.WasteReportsEndpoint);
    const lastMessage = messages[messages.length - 1];

    const { accounts, instance } = useMsal();

    const noTextInputMessages = [
        MessageAuthor.Location,
        MessageAuthor.FormSubmitUser,
        MessageAuthor.ReportCreatedMessage,
        MessageAuthor.ReportErrorMessage,
        MessageAuthor.GiveFeedbackMessage,
        MessageAuthor.SubmitOptions,
    ];

    const lastMessageIsNotTextInput = noTextInputMessages.includes(
        lastMessage?.author,
    );

    const addMessage = (message: MessageType) => {
        setMessages((prevMessages) => {
            return [...prevMessages, message];
        });
    };

    // Search through the messages. If a message contains an image, return the image url.
    const getUploadedImageUrl = () => {
        const uploadedImageMessage = messages.find(
            (message) => message.author === MessageAuthor.UploadedImage,
        );
        return uploadedImageMessage?.imageUrl;
    };

    // Replace a message that has the ImageUpload author message with a new message
    const addUploadedImageMessage = (message: MessageType) => {
        setMessages((currentMessages) => {
            return (
                currentMessages
                    // Replace the ImageUpload message with the UploadedImage message
                    .map((currentMessage) => {
                        if (
                            currentMessage?.author ===
                                MessageAuthor.ImageUpload ||
                            currentMessage?.author ===
                                MessageAuthor.UploadedImage
                        ) {
                            return message;
                        }
                        return currentMessage;
                    })
            );
        });
    };

    const restartReport = () => {
        setReportProgress([]);
        setMessages(getInitialMessages());
        setReportLocation();
        setLocationSavedInSessionStorage(false);
        setLocationSaved(false);
        generateNewSessionId();
        setStartMetricsSent(false);
        setEndMetricsSent(false);
        setSubmitOption(null);
        setChatbotThinking(false);
        resetFormattedReport();
        setReportCategory();
        setSubmitOption(null);
        setUserDetails(undefined);
        // Clear any URL params so if the user passed in a category
        // before, it gets cleared when they start a new report
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname,
        );
    };

    const saveAdditionalLocationDetails = (locationDetails: string) => {
        addMessage({
            text: locationDetails || "No",
            author: MessageAuthor.LocationDetailsAnswer,
        });
        addNewPropertiesToFormattedReport({
            ServiceIncidentDetailsObject: {
                RelativePositionDetailsObject: {
                    description: locationDetails,
                },
            },
        });
    };

    const saveAdditionalReportDetails = (reportDetails: string) => {
        addMessage({
            text: reportDetails || "No",
            author: MessageAuthor.ReportDetailsAnswer,
        });
        addNewPropertiesToFormattedReport({
            ServiceIncidentDetailsObject: {
                CoreServiceDetailsObject: {
                    IncidentDescriptionObject: {
                        description1: reportDetails,
                    },
                },
            },
        });
    };

    const getCustomMessageHandlerOrUndefined = () => {
        if (lastMessage.author === MessageAuthor.ExtraLocationDetails) {
            return saveAdditionalLocationDetails;
        } else if (lastMessage.author === MessageAuthor.ExtraReportDetails) {
            return saveAdditionalReportDetails;
        }
    };

    const getMaxInputLengthOrUndefined = () => {
        if (questionsWithMaxInputLength.includes(lastMessage.author)) {
            return EXTRA_DETAIL_MESSAGE_MAX_LENGTH;
        }
    };

    useEffect(() => {
        setChatHistory(messages);
        if (!getStartMetricsSent()) {
            // If this is the first user message, the end metrics can't have been sent yet
            // (because the user hasn't finished the conversation yet), so reset this flag
            // in case it has been set before
            setEndMetricsSent(false);

            // Get the last message the user sent
            const firstUserMessage = messages.find(
                (message) => message.author === MessageAuthor.User,
            );

            if (firstUserMessage) {
                setStartMetricsSent(true);
                recordConversationStart(
                    typeof firstUserMessage?.userMessageSource === "number"
                        ? firstUserMessage?.userMessageSource
                        : UserMessageSource.TextInput,
                ).then(() => {
                    console.log("Conversation start recorded");
                });
            }
        }

        const lastMessage = messages[messages.length - 1];
        if (lastMessage.completedForm && !locationSaved) {
            // If the chatbot has all the information it needs to create the report,
            // but the location hasn't been recorded yet then add a location message
            addMessage({
                text: addLocationMessageText,
                author: MessageAuthor.Location,
            });
        } else if (
            lastMessage.author === MessageAuthor.Location &&
            locationSaved
        ) {
            addMessage({
                author: MessageAuthor.ExtraLocationDetails,
                text: "Are there any more details about the location that will help us solve this issue? Type below...",
                advancedOptions: [
                    {
                        text: "No",
                        value: "",
                    },
                ],
            });
        } else if (lastMessage.author === MessageAuthor.LocationDetailsAnswer) {
            addMessage({
                author: MessageAuthor.ExtraReportDetails,
                text: "Do you have any more information that will help us resolve this issue?",
                advancedOptions: [
                    {
                        text: "No",
                        value: "",
                    },
                ],
            });
        } else if (
            lastMessage.author === MessageAuthor.ReportDetailsAnswer &&
            !userDetails
        ) {
            if (accounts.length > 0) {
                setUserDetails({
                    isAnon: false,
                    firstName: accounts[0].name,
                    lastName: "",
                    email: accounts[0].username,
                });
            } else {
                addMessage({
                    text: "How would you like to continue?",
                    author: MessageAuthor.SubmitOptions,
                    advancedOptions: [
                        {
                            text: "Login / Sign up",
                            value: SubmitReportOptions.MyWestminster,
                        },
                        {
                            text: "Continue as Guest",
                            value: SubmitReportOptions.Guest,
                        },
                        {
                            text: "Submit Report",
                            value: SubmitReportOptions.Skip,
                        },
                    ],
                });
            }
        } else if (
            lastMessage.author === MessageAuthor.SubmitOptions &&
            submitOption?.value === SubmitReportOptions.Guest
        ) {
            // If the last message was the submit options and the user chose to Continue as Guest
            // add a message asking for user details
            addMessage({
                text: SubmitReportOptions.Guest,
                displayText: "Continue as Guest",
                author: MessageAuthor.User,
            });

            addMessage({
                author: MessageAuthor.FormSubmitUser,
                displayText: "Please enter your details below",
                text: "",
            });
        } else if (
            lastMessage.author === MessageAuthor.SubmitOptions &&
            submitOption?.value === SubmitReportOptions.Skip
        ) {
            // If the last message was the submit options and the user chose to skip set the user details to anonymous
            setUserDetails({
                isAnon: true,
            });

            addMessage({
                text: "user details",
                displayText: "Submit Report",
                author: MessageAuthor.User,
            });
        } else if (
            userDetails &&
            !getEndMetricsSent() &&
            wasteReportsEndpoint
        ) {
            // If we're starting the feedback survey and we haven't sent the metrics before,
            // send the metrics now
            const userMessagesNumber = messages.filter(
                (message) =>
                    message.author === MessageAuthor.User && !message.hidden,
            ).length;

            if (accounts.length > 0) {
                finalizeReportWithToken(
                    wasteReportsEndpoint,
                    addMessage,
                    setChatbotThinking,
                    accounts,
                    instance,
                );
            } else {
                finalizeReport(
                    wasteReportsEndpoint,
                    addMessage,
                    setChatbotThinking,
                );
            }

            recordConversationEnd(
                getReportCategory(),
                userMessagesNumber,
                getLocationFromSessionStorage(),
                userDetails,
            ).then(() => {
                console.log("Conversation end recorded");
            });
        }
    }, [
        messages,
        initialMessagesLength,
        locationSaved,
        submitOption,
        wasteReportsEndpoint,
        userDetails,
        accounts,
        instance,
    ]);

    return (
        <div className={styles.scrim}>
            <div className={styles.chatbotContainer}>
                <div className={styles.chatbot}>
                    {restartReportOpen ? (
                        <RestartReport
                            closeModal={() => setRestartReportOpen(false)}
                            restartReport={restartReport}
                        />
                    ) : null}
                    {lightboxOpen ? (
                        <Lightbox
                            imageUrl={getUploadedImageUrl()}
                            closeLightbox={() => {
                                setLightboxOpen(false);
                            }}
                            buttons={[
                                <button
                                    key="replace-image-button"
                                    className={styles.replaceImageButton}
                                    onClick={() => {
                                        setLightboxOpen(false);
                                        setRestartReportOpen(true);
                                    }}
                                >
                                    Replace image
                                </button>,
                            ]}
                        />
                    ) : null}
                    <div className={styles.iconContainer}>
                        <button
                            className={styles.icon}
                            aria-label="Restart report"
                            data-testid="restartReport"
                            onClick={() => {
                                setRestartReportOpen(true);
                            }}
                        >
                            <img src={refreshIcon} alt="" />
                        </button>
                        <p className={styles.title}>Report it assistant</p>
                        <button
                            className={styles.icon}
                            onClick={closeModal}
                            aria-label="Close"
                        >
                            <img src={minimizeIcon} alt="" />
                        </button>
                    </div>
                    <Messages
                        messages={messages}
                        addMessage={addMessage}
                        setUserDetails={setUserDetails}
                        setChatbotThinking={setChatbotThinking}
                        chatbotThinking={chatbotThinking}
                        setLightboxOpen={setLightboxOpen}
                        addUploadedImageMessage={addUploadedImageMessage}
                        locationSaved={locationSaved}
                        setLocationSaved={setLocationSaved}
                        restartReport={restartReport}
                        customMessageHandler={getCustomMessageHandlerOrUndefined()}
                        setSubmitOption={setSubmitOption}
                        userDetailsCollected={!!userDetails}
                    />
                    <UserInput
                        addMessage={addMessage}
                        customMessageHandler={getCustomMessageHandlerOrUndefined()}
                        disabled={chatbotThinking || lastMessageIsNotTextInput}
                        submitOption={submitOption}
                        setChatbotThinking={setChatbotThinking}
                        maxInputLength={getMaxInputLengthOrUndefined()}
                    />
                </div>
            </div>
        </div>
    );
};
