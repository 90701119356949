import React from "react";
import styles from "./ChatbotThinking.module.css";

type ChatbotThinkingType = () => React.ReactElement;

export const ChatbotThinking: ChatbotThinkingType = (): React.ReactElement => {
    return (
        <div className={styles.chatbotThinkingContainer}>
            <div className={styles.chatbotThinking}>
                <div className={styles.typingDot}></div>
                <div className={styles.typingDot}></div>
                <div className={styles.typingDot}></div>
                <p aria-live="polite">Report it is thinking</p>
            </div>
        </div>
    );
};
