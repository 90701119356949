import React from "react";
import { Button } from "../../Button";
import styles from "./SubmitOptions.module.css";
import SubmitReportOptions from "../../../utils/submitOptionsEnum";
import Login from "../../Chatbot/Login";
import { UserDetails } from "../../../types/dataTypes";

type SubmitOptionsProps = ({
    setSubmitOption,
    setUserDetails,
    userDetailsCollected,
}: {
    setSubmitOption: ({
        value,
        displayText,
    }: {
        value: string;
        displayText: string;
    }) => void;
    setUserDetails: (details: UserDetails) => void;
    userDetailsCollected: boolean;
}) => React.ReactElement | null;

export const SubmitOptions: SubmitOptionsProps = ({
    setSubmitOption,
    setUserDetails,
    userDetailsCollected,
}) => {
    const handleSubmitOption = (value: string, displayText: string) => {
        setSubmitOption({
            value,
            displayText,
        });
    };

    return (
        <div className={styles.buttonsWrapper}>
            <Login
                setSubmitOption={setSubmitOption}
                setUserDetails={setUserDetails}
                userDetailsCollected={userDetailsCollected}
            />

            <Button
                variant="secondary"
                text="Continue as Guest"
                disabled={userDetailsCollected}
                value={SubmitReportOptions.Guest}
                onClick={() =>
                    handleSubmitOption(
                        SubmitReportOptions.Guest,
                        "Continue as Guest",
                    )
                }
            />

            <Button
                variant="secondary"
                text="Submit Report"
                disabled={userDetailsCollected}
                value={SubmitReportOptions.Skip}
                onClick={() =>
                    handleSubmitOption(
                        SubmitReportOptions.Skip,
                        "Submit Report",
                    )
                }
            />
        </div>
    );
};
