import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Components/App";
import "./index.css";
import "./styles/globals.css";
import { msalConfig, forgotPasswordRequest } from "./authConfig";
import {
    AuthenticationResult,
    EventMessage,
    EventType,
    PublicClientApplication,
} from "@azure/msal-browser";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
export const msalInstance = new PublicClientApplication(msalConfig);

// This solution is taken from this article:
// https://web.archive.org/web/20230726125552/https://weblog.west-wind.com/posts/2023/Apr/17/Preventing-iOS-Safari-Textbox-Zooming
// In short, on iPhone, inputs will be zoomed in when focused, which is not what we want.
// This behaves differently on Android though, and would make zooming into the page impossible.
// So we have to apply this change only for iPhones.

// An alternative solution, apparently, is to set the text size of the input to 16px, but that is significantly
// bigger than the current designs, so we'll go with this solution for now

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

const viewportContent = isIOS
    ? "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
    : "width=device-width, initial-scale=1";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/callbacks",
        element: null,
    },
]);

// Insipiration from this source:
// https://codesandbox.io/p/sandbox/typescript-sample-1xct1?file=%2Fsrc%2Fstyles%2Ftheme.ts

const sentryIsNonProd = ["dev", "anc-dev", "anc-staging", "staging"].includes(
    process.env.REACT_APP_MAINSITE_SENTRY_ENVIRONMENT as string,
);

Sentry.init({
    dsn: "https://71ac65a479ce562665db6b29a14bec34@o4505906803900416.ingest.us.sentry.io/4507135478398976",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({ useCompression: false }), // Unfortunatley compression needs to be disabled to avoid CSP errors
    ],
    environment: process.env.REACT_APP_MAINSITE_SENTRY_ENVIRONMENT,
    // Performance Monitoring
    tracesSampleRate: sentryIsNonProd ? 1.0 : 0.1,
    // Session Replay
    replaysSessionSampleRate: sentryIsNonProd ? 1.0 : 0.1,
    replaysOnErrorSampleRate: sentryIsNonProd ? 1.0 : 0.1,
});

msalInstance.initialize().then(() => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }
    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
        }
        if (event.eventType === EventType.LOGIN_FAILURE) {
            // Check for forgot password error
            // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
            if (event.error?.message.includes("AADB2C90118")) {
                msalInstance.loginRedirect(forgotPasswordRequest);
            }
        }
    });

    const root = ReactDOM.createRoot(
        document.getElementById("root") as HTMLElement,
    );

    const metaTag = document.createElement("meta");
    metaTag.name = "viewport";
    metaTag.content = viewportContent;
    document.head.append(metaTag);

    root.render(
        <React.StrictMode>
            <MsalProvider instance={msalInstance}>
                <RouterProvider router={router} />
            </MsalProvider>
        </React.StrictMode>,
    );
});
