import React, { useCallback, useEffect, useRef } from "react";
import styles from "./UserInput.module.css";
import { MessageAuthor, MessageType, UserMessageSource } from "../ChatbotModal";
import sendIcon from "../../../styles/assets/send.svg";
import { sendMessageToChatbotAndAddResponse } from "../../../utils/chatUtils";
import { ScreenReaderOnly } from "../../ScreenReaderOnly";
import { SubmitOptionType } from "../../../types/dataTypes";

type UserInputType = ({
    addMessage,
    disabled,
    setChatbotThinking,
    submitOption,
    customMessageHandler,
    maxInputLength,
}: {
    addMessage: (message: MessageType) => void;
    disabled: boolean;
    setChatbotThinking: (isThinking: boolean) => void;
    submitOption: SubmitOptionType | null;
    customMessageHandler?: (userMessage: string) => void;
    maxInputLength?: number;
}) => React.ReactElement | null;

const textInputMaxHeight = 300;

export const UserInput: UserInputType = ({
    addMessage,
    disabled,
    setChatbotThinking,
    submitOption,
    customMessageHandler,
    maxInputLength,
}) => {
    // Event handlers will only have the initial state.
    // A workaround is using a useRef:
    // https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state
    const disabledRef = useRef(disabled);
    const messageHandlerRef = useRef(customMessageHandler);
    useEffect(() => {
        disabledRef.current = disabled;
    }, [disabled]);

    useEffect(() => {
        messageHandlerRef.current = customMessageHandler;
    }, [customMessageHandler]);

    const getDisabledStyle = (
        defaultStyles: string,
        customDisabledStyle?: string,
    ) => {
        return disabled
            ? customDisabledStyle
                ? [defaultStyles, customDisabledStyle].join(" ")
                : [defaultStyles, styles.disabled].join(" ")
            : defaultStyles;
    };

    const sendUserMessage = useCallback(async () => {
        if (!disabledRef.current) {
            const userInput = document.getElementById(
                "userMessage",
            ) as HTMLTextAreaElement;
            // Reset the height
            userInput.style.height = "auto";
            const userMessage = userInput.value;
            if (userMessage && !submitOption?.value) {
                if (messageHandlerRef.current) {
                    messageHandlerRef.current(userMessage);
                    userInput.value = "";
                } else {
                    setChatbotThinking(true);
                    addMessage({
                        text: userMessage,
                        author: MessageAuthor.User,
                        userMessageSource: UserMessageSource.TextInput,
                    });
                    userInput.value = "";

                    await sendMessageToChatbotAndAddResponse(
                        userMessage,
                        addMessage,
                    );

                    setChatbotThinking(false);
                }
            }
            if (userMessage && submitOption?.value) {
                setChatbotThinking(true);
                addMessage({
                    text: userMessage,
                    author: MessageAuthor.User,
                    userMessageSource: UserMessageSource.TextInput,
                });
                userInput.value = "";
            }
        }
    }, [addMessage, setChatbotThinking, submitOption?.value]);

    useEffect(() => {
        const textInput = document.getElementById("userMessage");

        const changeScrollHeight = () => {
            if (textInput) {
                textInput.style.height = "auto";
                textInput.style.height =
                    (textInput.scrollHeight < textInputMaxHeight
                        ? textInput.scrollHeight
                        : textInputMaxHeight) + "px";
            }
        };

        const sendMessageOnEnter = async (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                event.preventDefault();
                await sendUserMessage();
            }
        };
        if (textInput) {
            textInput.addEventListener("input", changeScrollHeight);
            textInput.addEventListener("keydown", sendMessageOnEnter);
        }
        return () => {
            if (textInput) {
                textInput.removeEventListener("input", changeScrollHeight);
                textInput.removeEventListener("keydown", sendMessageOnEnter);
            }
        };
    }, [sendUserMessage]);

    return (
        <div className={getDisabledStyle(styles.userInput)}>
            <form
                className={styles.form}
                onSubmit={async (event) => {
                    event.preventDefault();
                    await sendUserMessage();
                }}
            >
                <input type="submit" hidden />
                <textarea
                    rows={1}
                    maxLength={maxInputLength}
                    aria-label="Type your message..."
                    className={getDisabledStyle(styles.textInput)}
                    readOnly={disabled}
                    id="userMessage"
                    placeholder="Type your message..."
                />
            </form>
            <button
                className={styles.sendButton}
                onClick={async () => {
                    await sendUserMessage();
                }}
            >
                <ScreenReaderOnly tag="p" role="none">
                    Send
                </ScreenReaderOnly>

                <img
                    className={getDisabledStyle(
                        styles.sendIcon,
                        styles.disabledIcon,
                    )}
                    src={sendIcon}
                    alt=""
                />
            </button>
        </div>
    );
};
