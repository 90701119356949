import React from "react";
import styles from "./ImageUpload.module.css";
import uploadIcon from "../../../styles/assets/upload.svg";
import {
    getDownloadURL,
    ref,
    uploadBytesResumable,
    UploadTask,
} from "firebase/storage";
import ImageUploading, {
    ErrorsType,
    ImageListType,
} from "react-images-uploading";
import {
    getFirebaseFunctions,
    getFirebaseStorage,
    IMAGE_CLASSIFICATION_FUNCTION_NAME,
} from "../../../utils/firebaseUtils";
import {
    MessageAuthor,
    MessageType,
    UserMessageSource,
} from "../../Chatbot/ChatbotModal";
import { httpsCallable } from "firebase/functions";
import {
    getAddress,
    getOrCreateSessionId,
    sendMessageToChatbotAndAddResponse,
    setReportLocation,
} from "../../../utils/chatUtils";
import {
    addImageToReport,
    getReportProgress,
} from "../../../utils/reportUtils";
import { Lightbox } from "../../Chatbot/Lightbox";
import { useFlags } from "../../../contexts/FlagsProvider/FlagsProvider";
import FeatureFlags from "../../../utils/flagsEnum";
import {
    base64ToArrayBuffer,
    categoryFirstLetterUppercase,
    getFileName,
    getResponseToNoClassification,
    isCategorySameAsClassification,
    replaceDashesAndUnderscores,
} from "../../../utils/imageUploadUtils";

type ImageUploadType = ({
    addUploadedImageMessage,
    chatbotThinking,
    setChatbotThinking,
    addMessage,
}: {
    addUploadedImageMessage: (message: MessageType) => void;
    chatbotThinking: boolean;
    setChatbotThinking: (thinking: boolean) => void;
    addMessage: (message: MessageType) => void;
}) => React.ReactElement | null;

type ReadMetadataRequestData = {
    image_uri: string;
    bucket_name: string;
    filename: string;
    session_id: string;
    bin_bag_detector_threshold: number;
    classification_confidence_threshold: number;
};

type ReadMetadataResponseData = {
    metadata: ImageMetadata;
    classifications: Classification[];
};

type Classification = {
    confidence: number;
    label: string;
};

export type ImageLocation = {
    latitude: string | null;
    longitude: string | null;
    address?: string;
};

export type ImageMetadata = ImageLocation & {
    timestamp: string;
};

type UploadFailure = {
    uploadFailure?: boolean;
};

const THIRTY_MEGABYTES = 31457280;
const MAX_IMAGES = 1;
const DEFAULT_CLASSIFICATION_CONFIDENCE_THRESHOLD = 0.5;
const DEFAULT_BIN_BAG_DETECTOR_THRESHOLD = 0.2;

export const ImageUpload: ImageUploadType = ({
    addUploadedImageMessage,
    chatbotThinking,
    setChatbotThinking,
    addMessage,
}) => {
    const [images, setImages] = React.useState<ImageListType>([]);
    const [showCancelButton, setShowCancelButton] = React.useState<boolean>();
    const [uploadError, setUploadError] = React.useState<UploadFailure>();
    const [lightboxVisible, setLightboxVisible] =
        React.useState<boolean>(false);

    const uploadPromise = React.useRef<UploadTask | null>(null);

    // Get the bin_bag_detector_threshold from the remote config
    const binBagDetectorThreshold =
        useFlags(FeatureFlags.BinBagDetectorThreshold) ||
        DEFAULT_BIN_BAG_DETECTOR_THRESHOLD;
    const classificationConfidenceThreshold =
        useFlags(FeatureFlags.ClassificationThreshold) ||
        DEFAULT_CLASSIFICATION_CONFIDENCE_THRESHOLD;

    const categoryMapping = useFlags(FeatureFlags.CategoryMapping);

    const urlParams = new URLSearchParams(window.location.search);
    const categoryParam = urlParams.get("category");

    // If we have a categoryParam, then use the mapping if it exists, otherwise use the categoryParam
    // If there is no categoryParam, then use an empty string
    const category = categoryParam
        ? categoryMapping?.[categoryParam] || categoryParam
        : "";

    const storage = getFirebaseStorage();
    const functions = getFirebaseFunctions();
    const getMetadata = httpsCallable<
        ReadMetadataRequestData,
        ReadMetadataResponseData
    >(functions, IMAGE_CLASSIFICATION_FUNCTION_NAME);

    const submitImage = async () => {
        setShowCancelButton(true);
        setChatbotThinking(true);
        setLightboxVisible(false);
        setUploadError({ uploadFailure: false });
        const image = images[0];
        // Spaces are now allowed in the file name
        const fileName = image?.file?.name
            ? image.file.name.replaceAll(" ", "_")
            : "noFileName";

        const fullFilename = getFileName(fileName);
        const imageRef = ref(storage, `reportItImage/${fullFilename}`);

        // The data url starts with something like "data:image/jpeg;base64," so we need to remove this first part
        // The file type is still captured by passing it in as a parameter to the uploadBytesResumable function
        const imageBytes = base64ToArrayBuffer(image.data_url.split(",")[1]);

        const metadata = {
            contentType: image.file?.type,
        };

        uploadPromise.current = uploadBytesResumable(
            imageRef,
            imageBytes,
            metadata,
        );

        uploadPromise.current.then(
            (snapshot) => {
                setShowCancelButton(false);
                getDownloadURL(snapshot.ref).then(async (url) => {
                    addUploadedImageMessage({
                        author: MessageAuthor.UploadedImage,
                        text: "Image uploaded successfully!",
                        imageUrl: url,
                    });
                    try {
                        const response = await getMetadata({
                            image_uri: url,
                            bucket_name: snapshot.ref.bucket,
                            filename: snapshot.ref.fullPath,
                            session_id: getOrCreateSessionId(),
                            bin_bag_detector_threshold: binBagDetectorThreshold,
                            classification_confidence_threshold:
                                classificationConfidenceThreshold,
                        });

                        const address = await getAddress(
                            response.data.metadata,
                        );

                        // The API returns an array of classifications ordered by confidence.
                        // For now, only use the most confident classification
                        const primaryClassificationLabel =
                            response.data.classifications?.[0]?.label;

                        addUploadedImageMessage({
                            author: MessageAuthor.UploadedImage,
                            text: "Image uploaded successfully!",
                            imageUrl: url,
                            classification: primaryClassificationLabel,
                        });

                        const latitude = response.data.metadata.latitude;
                        const longitude = response.data.metadata.longitude;

                        if (latitude && longitude && address) {
                            setReportLocation(latitude, longitude, address);
                        }

                        // Adds the image file details to the formatted report
                        addImageToReport(url, snapshot.ref.name);

                        // The image classification is currently only used to get the starting category.
                        // If there is already report progress (meaning we have the first category), then we
                        // do not need to send any information from the image to the chatbot, or get the chatbot
                        // to ask another question
                        if (!getReportProgress()) {
                            addMessage({
                                text: "",
                                author: MessageAuthor.User,
                                userMessageSource:
                                    UserMessageSource.ImageUpload,
                                hidden: true,
                            });
                            if (primaryClassificationLabel) {
                                const isCategoryFromUrl = !!category;

                                const isSameCategory =
                                    isCategorySameAsClassification(
                                        primaryClassificationLabel,
                                        category,
                                    );

                                const conflictingCategoryMessage: MessageType =
                                    {
                                        author: MessageAuthor.ImageClassification,
                                        text: `You previously selected ${replaceDashesAndUnderscores(
                                            category,
                                            " ",
                                        )}, how would you like to proceed?`,
                                        advancedOptions: [
                                            {
                                                text: primaryClassificationLabel,
                                                value: primaryClassificationLabel,
                                            },
                                            {
                                                text: replaceDashesAndUnderscores(
                                                    categoryFirstLetterUppercase(
                                                        category,
                                                    ),
                                                    " ",
                                                ),
                                                value: category,
                                            },
                                            {
                                                text: "Other",
                                                value: "",
                                            },
                                        ],
                                    };

                                const oneCategoryMessage: MessageType = {
                                    author: MessageAuthor.ImageClassification,
                                    text: `Is ${primaryClassificationLabel} correct?`,
                                    advancedOptions: [
                                        {
                                            text: "Yes",
                                            value: primaryClassificationLabel,
                                        },
                                        {
                                            text: "No",
                                            value: "",
                                        },
                                    ],
                                };

                                if (isCategoryFromUrl && isSameCategory) {
                                    // If the category in the URL matches the category from the image classification,
                                    // then we do not need to ask the user if the category is correct
                                    // instead send it straight to the chatbot
                                    await sendMessageToChatbotAndAddResponse(
                                        category,
                                        addMessage,
                                    );
                                } else if (
                                    isCategoryFromUrl &&
                                    !isSameCategory
                                ) {
                                    // If the category in the URL does not match the category from the image classification,
                                    // then we need to ask the user which category is correct
                                    addMessage(conflictingCategoryMessage);
                                } else {
                                    // if we are in this block then we only had a category from the image classification
                                    // and no category from the URL
                                    addMessage(oneCategoryMessage);
                                }
                            } else {
                                await getResponseToNoClassification(
                                    addMessage,
                                    category,
                                );
                            }
                        }
                    } catch (e) {
                        console.log(e);
                        await getResponseToNoClassification(
                            addMessage,
                            category,
                        );
                    }
                    setChatbotThinking(false);
                    setShowCancelButton(false);
                });
            },
            () => {
                // If the upload is cancelled, stop the chatbot thinking and remove the cancel button
                setShowCancelButton(false);
                setChatbotThinking(false);
                // If the upload was cancelled by the user, then the images array will be empty
                // If the upload failed, then the images array will not be empty
                // so we need to clear the images and display an error message
                if (images) {
                    setImages([]);
                    setUploadError({ uploadFailure: true });
                }
            },
        );
    };

    const getDragClass = (isDragging: boolean) => {
        return [styles.uploadContainer, isDragging ? styles.dragging : ""].join(
            " ",
        );
    };

    const getErrorMessages = (
        errors: ErrorsType & { uploadFailure?: boolean },
    ) => {
        let errorMessage = "";
        if (errors?.acceptType) {
            errorMessage = "This file type is not supported";
        } else if (errors?.maxFileSize) {
            errorMessage = "File size is too big";
        } else if (errors?.resolution) {
            errorMessage = "Image resolution is too low";
        } else if (errors?.uploadFailure) {
            errorMessage =
                "We were unable to process your image. You can re-upload an image OR type your issue below";
        }
        return (
            <>
                {errorMessage ? (
                    <div className={styles.errorContainer}>
                        <div aria-live={"polite"} className={styles.errorText}>
                            {errorMessage}
                        </div>
                    </div>
                ) : null}
            </>
        );
    };

    const cancelUpload = () => {
        if (uploadPromise.current) {
            uploadPromise.current.cancel();
        }
        setImages([]);
        setLightboxVisible(false);
    };

    const showPreview = (imageList: ImageListType) => {
        setLightboxVisible(true);
        setImages(imageList);
    };

    return (
        <>
            {images.length && lightboxVisible ? (
                <Lightbox
                    imageUrl={images[0]?.data_url}
                    closeLightbox={cancelUpload}
                    buttons={[
                        <button
                            key="cancel-image-button"
                            className={[styles.button, styles.cancel].join(" ")}
                            onClick={cancelUpload}
                        >
                            Cancel
                        </button>,
                        <button
                            key="submit-image-button"
                            className={[styles.button, styles.submit].join(" ")}
                            onClick={submitImage}
                        >
                            Submit
                        </button>,
                    ]}
                />
            ) : null}

            <div className={chatbotThinking ? styles.disabled : ""}>
                <ImageUploading
                    value={images}
                    maxFileSize={THIRTY_MEGABYTES}
                    acceptType={["jpg", "jpeg", "png", "gif"]}
                    resolutionWidth={200}
                    resolutionHeight={200}
                    resolutionType={"more"}
                    onChange={showPreview}
                    maxNumber={MAX_IMAGES}
                    dataURLKey="data_url"
                >
                    {({ onImageUpload, isDragging, dragProps, errors }) => (
                        <>
                            <button
                                aria-label="Upload your photo here"
                                className={getDragClass(isDragging)}
                                onClick={onImageUpload}
                                {...dragProps}
                            >
                                <div className={styles.uploadContents}>
                                    <img src={uploadIcon} alt="" />
                                    <div className={styles.uploadPhotoText}>
                                        Upload photo
                                    </div>
                                    <div className={styles.secondaryText}>
                                        We'll predict your problem
                                    </div>
                                </div>
                            </button>
                            {getErrorMessages(
                                Object.assign({}, errors, uploadError),
                            )}
                        </>
                    )}
                </ImageUploading>
            </div>
            {showCancelButton ? (
                <button
                    className={[styles.button, styles.cancel].join(" ")}
                    onClick={cancelUpload}
                >
                    Cancel upload
                </button>
            ) : null}
        </>
    );
};
